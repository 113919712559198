.career-img-brno {
    border-radius: 0.3rem;
}
.career-img-olomouc {
    border-radius: 0.3rem;
}



@media screen and (max-width: 360px) {
    .career-img-section {
        background: linear-gradient(0deg, var(--white) 0%, var(--white) 55%, rgba(26,26,26,1) 55%, rgba(26,26,26,1) 100%);
        height: 25rem;
    }
    .career-img-container-position {
        position: relative;
        top: 3rem;
    }
    .career-img-brno {
        height: 10rem;
        position: absolute;
        top: +1rem;
        left: 0;
        margin-left: 0.75rem

    }
    .career-img-olomouc {
        height: 10rem;
        position: absolute;
        top: +10rem;
        right: 0;
        margin-right: 0.75rem
    }
    .career-img-header-brno {
        position: absolute;
        left: 1rem;
        top: -1rem;
    }
    .career-img-header-olomouc {
        position: absolute;
        right: 1rem;
        top: 14.5em;
    }
    .career-img-hr-brno {
        display: none;
    }
    .career-img-hr-olomouc {
        display: none;
    }
    .career-header-br{
        display: none;
    }
}
@media  screen and (min-width: 360px) {
    .career-img-section {
        background: linear-gradient(0deg, var(--white) 0%, var(--white) 45%, rgba(26,26,26,1) 45%, rgba(26,26,26,1) 100%);
        height: 30rem;
    }
    .career-img-container-position {
        position: relative;
        top: 3rem;
    }
    .career-img-brno {
        height: 13.5rem;
        position: absolute;
        top: +1.5rem;
        left: 0;
        margin-left: 0.75rem

    }
    .career-img-olomouc {
        height: 13.5rem;
        position: absolute;
        top: 12rem;
        right: 0;
        margin-right: 0.75rem
    }
    .career-img-header-brno {
        position: absolute;
        left: 1rem;
        top: -0.75rem;
    }
    .career-img-header-olomouc {
        position: absolute;
        right: 1rem;
        top: 16.75em;
    }
    .career-img-hr-brno {
        display: none;
    }
    .career-img-hr-olomouc {
        display: none;
    }
    .career-header-br{
        display: none;
    }
}
@media screen and (min-width: 576px) {
    .career-img-section {
        background: linear-gradient(0deg, var(--white) 0%, var(--white) 48%, rgba(26,26,26,1) 48%, rgba(26,26,26,1) 100%);
        height: 37rem;
    }
    .career-img-container-position {
        position: relative;
        top: 3rem;
    }
    .career-img-brno {
        height: 17rem;
        position: absolute;
        top: +1.5rem;
        left: 0;

    }
    .career-img-olomouc {
        height: 17rem;
        position: absolute;
        top: +15rem;
        right: 0;
    }
    .career-img-header-brno {
        position: absolute;
        left: 1rem;
        top: -1rem;
    }
    .career-img-header-olomouc {
        position: absolute;
        right: 1rem;
        top: 32.5rem;
    }
    .career-img-hr-brno {
        display: none;
    }
    .career-img-hr-olomouc {
        display: none;
    }
    .career-header-br{
        display: none;
    }
}
@media screen and (max-width: 768px) {

}
@media screen and (min-width: 768px) {
    .career-img-section {
        background: linear-gradient(0deg, var(--white) 0%, var(--white) 40%, rgba(26,26,26,1) 40%, rgba(26,26,26,1) 100%);
        height: 24rem;
    }
    .career-img-container-position {
        position: relative;
        top: 0rem;
    }
    .career-img-brno {
        height: 17rem;
        position: absolute;
        left: 0;

        filter: grayscale(0.75);
        transition: 0.3s;
    }
    .career-img-brno:hover {
        filter: grayscale(0);
    }
    .career-img-olomouc {
        height: 17rem;
        position: absolute;
        top: +6rem;
        right: 0;

        filter: grayscale(0.75);
        transition: 0.3s;
    }
    .career-img-olomouc:hover {
        filter: grayscale(0);
    }
    .career-img-header-brno {
        position: absolute;
        left: 30rem;
        top: 1.5rem;
    }
    .career-img-header-olomouc {
        position: absolute;
        right: 30rem;
        top: 19rem;
    }
    .career-img-hr-brno {
        display: inherit;
        border-top: 2px solid var(--white);
        position: relative;
        top: 3rem;
        left: +2rem;
        margin: 0;
        width: 8rem;
    }
    .career-img-hr-olomouc {
        display: inherit;
        border-top: 2px solid var(--black);
        position: relative;
        top: 20.5rem;
        right: +2rem;
        margin: 0;
        width: 8rem;
    }
    .career-img-hr-olomouc {
        display: inherit;
    }
    .career-header-br{
        display: inherit;
    }
}
@media screen and (min-width: 992px) {
    .career-img-section {
        background: linear-gradient(0deg, var(--white) 0%, var(--white) 40%, rgba(26,26,26,1) 40%, rgba(26,26,26,1) 100%);
        height: 30rem;
    }
    .career-img-container-position {
        position: relative;
        top: 0rem;
    }
    .career-img-brno {
        height: 22rem;
        position: absolute;
        left: 0;

    }
    .career-img-olomouc {
        height: 22rem;
        position: absolute;
        top: +8rem;
        right: 0;
    }
    .career-img-header-brno {
        position: absolute;
        left: 38rem;
        top: 2rem;
    }
    .career-img-header-olomouc {
        position: absolute;
        right: 38rem;
        top: 25rem;
    }
    .career-img-hr-brno {
        border-top: 2px solid var(--white);
        position: relative;
        top: 4rem;
        margin: 0;
        width: 11rem;
    }
    .career-img-hr-olomouc {
        border-top: 2px solid var(--black);
        position: relative;
        top: 27rem;
        margin: 0;
        width: 11rem;
    }
}
@media screen and (min-width: 1200px) {
    .career-img-section {
        background: linear-gradient(0deg, var(--white) 0%, var(--white) 40%, rgba(26,26,26,1) 40%, rgba(26,26,26,1) 100%);
        height: 35rem;
    }
    .career-img-container-position {
        position: relative;
        top: 1rem;
    }
    .career-img-brno {
        height: 25rem;
        position: absolute;
        left: 0;
    }
    .career-img-olomouc {
        height: 25rem;
        position: absolute;
        top: +8rem;
        right: 0;
    }
    .career-img-header-brno {
        position: absolute;
        left: 45rem;
        top: 2rem;
    }
    .career-img-header-olomouc {
        position: absolute;
        right: 45rem;
        top: 27rem;
    }
    .career-img-hr-brno {
        border-top: 2px solid var(--white);
        position: relative;
        top: 4rem;
        margin: 0;
        width: 13rem;
    }
    .career-img-hr-olomouc {
        border-top: 2px solid var(--black);
        position: relative;
        top: 29rem;
        margin: 0;
        width: 13rem;
    }
}
@media screen and (min-width: 1400px) {
    .career-img-section {
        background: linear-gradient(0deg, var(--white) 0%, var(--white) 35%, rgba(26,26,26,1) 20%, rgba(26,26,26,1) 100%);
        height: 45rem;
    }
    .career-img-container-position {
        position: relative;
        top: 5rem;
    }
    .career-img-brno {
        height: 30rem;
        position: absolute;
        left: 0rem;
    }
    .career-img-olomouc {
        height: 30rem;
        position: absolute;
        right: 0rem;
        top: +10rem;
    }
    .career-img-header-brno {
        position: absolute;
        left: 52rem;
        top: 2.5rem;
    }
    .career-img-header-olomouc {
        position: absolute;
        right: 52rem;
        top: 32.5rem;
    }
    .career-img-hr-brno {
        border-top: 2px solid var(--white);
        position: relative;
        top: 5rem;
        margin: 0;
        width: 15rem;
    }
    .career-img-hr-olomouc {
        border-top: 2px solid var(--black);
        position: relative;
        top: 35rem;
        margin: 0;
        width: 15rem;
    }
}
