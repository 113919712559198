@media screen and (max-width: 360px) {
    .cybersec-training-form-martin-circle {
        width: 100%;
        margin-top: -4rem;
        margin-left: 0rem;
        margin-bottom: 1rem;
    }   
}
@media screen and (min-width: 360px) {
    .cybersec-training-form-martin-circle {
        width: 70%;
        margin-top: -7rem;
        margin-left: 0rem;
        margin-bottom: 2rem;
    }
}
@media screen and (min-width: 576px) {
    .cybersec-training-form-martin-circle {
        width: 80%;
        margin-top: -7rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0rem;
    }
}
@media screen and (min-width: 768px) {
    .cybersec-training-form-martin-circle {
        width: 25rem;
        margin-top: -7rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0rem;

    }
}

@media screen and (min-width: 992px) {
    .cybersec-training-form-martin {
        width: 20rem;
        /*margin-top: -10rem;*/
        left: -5rem;
        bottom: -3rem;
        position: relative;
    }
}
@media screen and (min-width: 1200px) {
    .cybersec-training-form-martin {
        width: 30rem;
        /*margin-top: -10rem;*/
        left: -8rem;
        bottom: -6.25rem;
        position: relative;
    }
}
@media screen and (min-width: 1400px) {
    .cybersec-training-form-martin {
        width: 34rem;
        /*margin-top: -10rem;*/
        left: -12rem;
        bottom: -3rem;
        position: relative;
    }
}

@media screen and (min-width: 2000px) {
    .cybersec-training-form-martin {
        width: 35rem;
        /*margin-top: -10rem;*/
        left: -15rem;
        bottom: -3rem;
        position: relative;
    }
}