.contact-team-link-mail {
    text-decoration: underline;
    animation-name: contact-link-mail-back;
    animation-duration: 0.3s;
}
.contact-team-link-mail:hover {
    color: var(--black);
    animation-name: contact-link-mail-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes contact-link-mail-forth { 
    from {
        color: var(--divelit-grey-50);
    }
    to { 
        color: var(--black);
    }
}
@keyframes contact-link-mail-back { 
    from { 
        color: var(--black);
    }
    to {
        color: var(--divelit-grey-50);
    }
}
