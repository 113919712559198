/*
@media screen and (max-width: 360px) {

}
@media screen and (min-width: 360px) {

}
@media screen and (min-width: 576px) {

}
@media screen and (max-width: 768px) {

}
@media screen and (min-width: 768px) {

}
@media screen and (min-width: 992px) {

}
@media screen and (min-width: 1200px) {

}
@media screen and (min-width: 1400px) {

};
*/

/* Required for blog-page background*/

.style-bg {
    background: var(--white-smoke); 
    /*color: var(--white);  */
    width: 100%;
}

/*scroll behavior*/
html {
    scroll-behavior: smooth;
    font-size: 16px;
    font-family: poppins-light;
}


.w-md-100 {
    max-width: 50%;
}
@media screen and (min-width: 768px) {
    .w-md-100 {
        max-width: 100%;
    }
}


.btn:focus {
  outline: none !important;
  box-shadow: none !important;

}

.style-end-page-padding {
    padding-bottom: 3em;
}

@media screen and (max-width: 768px) {
    .style-large-container-spacer {
        padding-top: 1em;
        padding-bottom: 1em;
    }
    .style-medium-container-spacer {
        padding-top: 2em;
        padding-bottom: 1em;
    }
    .style-small-container-spacer {
        padding-top: 1em;
        padding-bottom: 1em;
    }

    .style-spacer {
        padding-top: 0.5em;
    }
}
@media screen and (min-width: 768px) {
    .style-large-container-spacer {
        padding-top: 1em;
        padding-bottom: 1em;
    }
    .style-medium-container-spacer {
        padding-top: 2em;
        padding-bottom: 1em;
    }
    .style-small-container-spacer {
        padding-top: 1em;
        padding-bottom: 1em;
    }

    .style-spacer {
        padding-top: 1em;
    }
}
@media screen and (min-width: 992px) {
    .style-large-container-spacer {
        padding-top: 1em;
        padding-bottom: 1em;
    }
    .style-medium-container-spacer {
        padding-top: 2em;
        padding-bottom: 1em;
    }    
    .style-small-container-spacer {
        padding-top: 1em;
        padding-bottom: 1em;
    }

    .style-spacer {
        padding-top: 1.5em;
    }
}
@media screen and (min-width: 1200px) {
    .style-large-container-spacer {
        padding-top: 4em;
        padding-bottom: 4em;
    }
    .style-medium-container-spacer {
        padding-top: 2em;
        padding-bottom: 2em;
    }
    .style-small-container-spacer {
        padding-top: 2em;
        padding-bottom: 2em;
    }

    .style-spacer {
        padding-top: 2em;
    }
}
@media screen and (min-width: 1400px) {
    .style-large-container-spacer {
        padding-top: 5em;
        padding-bottom: 5em;
    }
    .style-medium-container-spacer {
        padding-top: 2.5em;
        padding-bottom: 2.5em;
    }    
    .style-small-container-spacer {
        padding-top: 2em;
        padding-bottom: 2em;
    }

    .style-spacer {
        padding-top: 2.5em;
    }
}



.style-container-img {
    display: block;
    margin: auto;
}
@media screen and (max-width: 768px) {
    .style-container-img {
        height: 12.5em;
    }
}
@media screen and (min-width: 768px) {
    .style-container-img {
        height: 15em;
    }
}
@media screen and (min-width: 992px) {
    .style-container-img {
        height: 15em;
    }
}
@media screen and (min-width: 1200px) {
    .style-container-img {
        height: 15em;
    }
}
@media screen and (min-width: 1400px) {
    .style-container-img {
        height: 17.5em;
    }
}



@media screen and (max-width: 768px) {
    .style-container-tripple-img {
        height: 7.5em;
    }
}
@media screen and (min-width: 768px) {
    .style-container-tripple-img {
        height: 7.5em;
    }
}
@media screen and (min-width: 992px) {
    .style-container-tripple-img {
        height: 10em;
    }
}
@media screen and (min-width: 1200px) {
    .style-container-tripple-img {
        height: 10em;
    }
}
@media screen and (min-width: 1400px) {
    .style-container-tripple-img {
        height: 12.5em;
    }
}
