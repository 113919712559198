@media screen and (max-width: 576px) {
    .home-experts-img {
        height: 2.5em;
    }
}
@media screen and (min-width: 576px) {
    .home-experts-img {
        height: 2.5em;
    }
}
@media screen and (min-width: 768px) {
    .home-experts-img {
        height: 3em;
    }
}
@media screen and (min-width: 992px) {
    .home-experts-img {
        height: 3.25em;
    }
}
@media screen and (min-width: 1200px) {
    .home-experts-img {
       height: 3.5em;
    }
}
@media screen and (min-width: 1400px) {
    .home-experts-img {
       height: 4em;
    }
}




@media screen and (max-width: 576px) {
    .home-experts-img-1 {
        height: 2.0em;
    }
}
@media screen and (min-width: 576px) {
    .home-experts-img-1 {
        height: 2.2em;
    }
}
@media screen and (min-width: 768px) {
    .home-experts-img-1 {
        height: 2.4em;
    }
}
@media screen and (min-width: 992px) {
    .home-experts-img-1 {
        height: 2.6em;
    }
}
@media screen and (min-width: 1200px) {
    .home-experts-img-1 {
       height: 2.8em;
    }
}
@media screen and (min-width: 1400px) {
    .home-experts-img-1 {
       height: 3em;
    }
}

.home-experts-bg-img {
    background-repeat: no-repeat;
}



@media screen and (max-width: 360px) {
    .home-experts-bg-img {
        background: linear-gradient(0deg, rgba(26,26,26,1) 0%, rgba(26,26,26,0.9) 40%, rgba(26,26,26,0) 100%), url('../../images/pages/home/divelit_d_team.webp');
        background-repeat: no-repeat;
        background-size: 350%;
        background-position: 83% top;

        /*min-height: 35rem;*/
    }

    .home-experts-paragraph-position {
        padding-top: +10rem;
        z-index: 2;
    }
    .home-experts-facts-position {
        width: 100%; 
        z-index: 2;
        position: relative;
        top: +1.25rem;
        height: auto;
    }
}
@media screen and (min-width: 360px) {
    .home-experts-bg-img {
        background: linear-gradient(0deg, rgba(26,26,26,1) 0%, rgba(26,26,26,0.9) 40%, rgba(26,26,26,0) 100%), url('../../images/pages/home/divelit_d_team.webp');
        background-repeat: no-repeat;
        background-size: 310%;
        background-position: 83% top;
        height: auto;
        /*min-height: 35rem;*/
    }
    .home-experts-paragraph-position {
        padding-top: +11rem;
        z-index: 2;
    }
    .home-experts-facts-position {
        width: 100%; 
        position: relative;
        top: +1.25rem;
        z-index: 2;
        height: auto;
    }

}
@media screen and (min-width: 576px) {
    .home-experts-bg-img {
        background: linear-gradient(0deg, rgba(26,26,26,1) 0%, rgba(26,26,26,0.9) 40%, rgba(26,26,26,0) 100%), url('../../images/pages/home/divelit_d_team.webp');
        background-size: 100rem;
        background-position: 85% top;
        height: auto;
        /*min-height: 35rem;*/
    }
    .home-experts-paragraph-position {
        position: relative;
        top: +1rem;
        z-index: 2;
    }
    .home-experts-facts-position {
        width: 100%; 
        position: relative;
        top: +1.5rem; 
        z-index: 2;
        height: auto;
    }
}
@media screen and (min-width: 768px) {
    .home-experts-bg-img {
        /*padding-top: 2rem;*/
        background: url('../../images/pages/home/divelit_d_team.webp');
        background-repeat: no-repeat;
        background-size: 85rem;
        background-position: 80% bottom;
        height: auto;
        /*min-height: 35rem;*/
    }
    .home-experts-paragraph-position {
        padding-top: 1rem;
        position: relative;
        top: +8rem;
        z-index: 2;
    }
    .home-experts-facts-position {
        width: 50%;
        margin-left: 50%;
        position: relative;
        top: -7.75rem;
        z-index: 2;
        height: 0;
    }

}
@media screen and (min-width: 992px) {
    .home-experts-bg-img {
        /*padding-top: 2rem;*/
        background: linear-gradient(90deg, rgba(25,25,25,1) 0%, rgba(25,25,25,0.1) 82.5%, rgba(25,25,25,0.8) 90%, rgba(25,25,25,1) 100%), url('../../images/pages/home/divelit_d_team.webp');
        background-repeat: no-repeat;
        /*background-size: 86rem;*/
        background-position: 80% bottom;
        height: auto;
        /*min-height: 35rem;*/
    }
    .home-experts-bg-img {
        background-size: 90rem;
        background-position: 80% bottom;
        /*min-height: 35rem;*/
    }
    .home-experts-paragraph-position {
        padding-top: 0.5rem;
        position: relative;
        top: +10rem;
        z-index: 2;
    }
    .home-experts-facts-position {
        width: 50%;
        margin-left: 50%;
        position: relative;
        top: -8.25rem;
        z-index: 2;
        height: 0;
    }
}
@media screen and (min-width: 1200px) {
    .home-experts-bg-img {
        background-size: auto;
        background-position: 80% bottom;
        background-repeat: no-repeat;
        /*min-height: 35rem;*/
        height: auto;
    }
    .home-experts-paragraph-position {
        padding-top: 5rem;
        position: relative;
        top: +18rem;
        z-index: 2;
    }
    .home-experts-facts-position {
        width: 50%;
        margin-left: 50%;
        position: relative;
        top: +1.75em;
        z-index: 2;
        height: auto;
    }
}
@media screen and (min-width: 1400px) {
    .home-experts-paragraph-position {
        padding-top: 0rem;
        position: relative;
        top: +20rem;
        z-index: 2;
    }
    .home-experts-facts-position {
        width: 50%;
        margin-left: 45%;
        position: relative;
        top: +2rem;
        z-index: 2;
        height: auto;
    }
}
