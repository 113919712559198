.our-work-linet-bg {
    background-image: url("../../logos/partners/linet_logo.png");
    /*background-color: red;*/
    background-position: right top;
    background-size: 60rem;
    background-repeat: no-repeat;
}

.our-work-linet-bg-2{
    background-image: url("../../images/pages/software/divelit_code_screen.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
}
