.blog-older-img {
    display: block;
    margin-left: 0;

    height: 100%;
    object-fit: cover;
    /*border-radius: 0.3rem 0rem 0rem 0.3rem; */
}
.blog-older-author-profile-img {
    height: 2.5em;
    width: 2.5em;
    border-radius: 100%;
}
@media screen and (max-width: 576px) {
    .blog-older-img {
        min-height: 12.5em;
        width: 100%;
        object-fit: cover;
    }
    .blog-older-author-profile-img {
        height: 2em;
        width: 2em;
        object-fit: cover;
    }
}
@media screen and (min-width: 576px) {
    .blog-older-img {
        min-height: 12.5em;
        width: 100%;
        object-fit: cover;
    }
    .blog-older-author-profile-img {
        height: 2em;
        width: 2em;
        object-fit: cover;
    }
}
@media screen and (min-width: 768px) {
    .blog-older-img {
        min-height: 15em;
        width: 100%; 
        object-fit: cover;
    }
    .blog-older-author-profile-img {
        height: 2.5em;
        width: 2.5em;
        object-fit: cover;
    }
}
@media screen and (min-width: 992px) {
    .blog-older-img {
        min-height: 17em;
        width: 100%;
        object-fit: cover;
    }
    .blog-older-author-profile-img {
        height: 2.5em;
        width: 2.5em;
        object-fit: cover;
    }
}
@media screen and (min-width: 1200px) {
    .blog-older-img {
        min-height: 18em;
        width: 100%;
        object-fit: cover;
    }
    .blog-older-author-profile-img {
        height: 2.5em;
        width: 2.5em;
        object-fit: cover;
    }
}
@media screen and (min-width: 1400px) {
    .blog-older-img {
        min-height: 100%;
        width: 100%;
        object-fit: cover;
    }
    .blog-older-author-profile-img {
        height: 2.5em;
        width: 2.5em;
        object-fit: cover;
    }
}



/* [1] The container */
.blog-older-img-hover-zoom {
    height: auto;
    width: auto;
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}
  /* [2] Transition property for smooth transformation of images */
.blog-older-img-hover-zoom img {
    transition: transform .5s, filter 0.5s ease;
    filter: brightness(92.5%);
}
  /* [3] Finally, transforming the image when container gets hovered */
.blog-older-img-hover-zoom:hover img {
    transform: scale(1.2);
    filter: brightness(100%);
}



.blog-older-header-black {
    color: var(--black);
    text-decoration: none;
    transition: 0.3s;
}
.blog-older-header-black:hover {
    text-decoration: underline;
    color: var(--black);
}



.blog-older-categories li {
    display: inline;
}

.blog-older-categories li a {
    color: var(--black);
    text-decoration: none;
}

.blog-older-categories li a:hover {
    color: var(--black);
    text-decoration: underline;
}



.blog-older-read-more {
    font-weight: bold;
    color: var(--black);
    text-decoration: none;
    transition: 0.3s;
}
.blog-older-read-more:hover{
    color: var(--black);
    text-decoration: underline;
}



.blog-older-container {
    background: var(--white);
    box-shadow: 1px 1px 20px 4px var(--divelit-grey-10);
    border-radius: 0.3rem;
}



@media screen and (max-width: 992px) {
    .blog-older-container-ms {
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .blog-older-container-ms-img-border {
        border-radius: 0.3rem 0.3rem 0.0rem 0.0rem;
    }
    .blog-older-container-ms-text-border {
        border-radius: 0.0rem 0.0rem 0.3rem 0.3rem;
    }
    
    .blog-older-container-me {
        margin-right: 0rem;
        margin-left: 0rem;
    }
    .blog-older-container-me-img-border {
        border-radius: 0.3rem 0.3rem 0.0rem 0.0rem;
    }
    .blog-older-container-me-text-border {
        border-radius: 0.0rem 0.0rem 0.3rem 0.3rem;
    }
}
@media screen and (min-width: 992px) {
    .blog-older-container-ms {
        margin-left: 4rem;
        margin-right: 0rem;
    }
    .blog-older-container-ms-img-border {
        border-radius: 0.0rem 0.3rem 0.3rem 0.0rem;
    }
    .blog-older-container-ms-text-border {
        border-radius: 0.3rem 0.0rem 0.0rem 0.3rem;
    }
    .blog-older-container-me {
        margin-right: 4rem;
        margin-left: 0rem;
    }
    .blog-older-container-me-img-border {
        border-radius: 0.3rem 0.0rem 0.0rem 0.3rem;
    }
    .blog-older-container-me-text-border {
        border-radius: 0.0rem 0.3rem 0.3rem 0.0rem;
    }
}

@media screen and (min-width: 1200px) {
    .blog-older-container-ms {
        margin-left: 6rem;
        margin-right: 0rem;
    }
    .blog-older-container-ms-img-border {
        border-radius: 0.0rem 0.3rem 0.3rem 0.0rem;
    }
    .blog-older-container-ms-text-border {
        border-radius: 0.3rem 0.0rem 0.0rem 0.3rem;
    }
    .blog-older-container-me {
        margin-right: 6rem;
        margin-left: 0rem;
    }
    .blog-older-container-me-img-border {
        border-radius: 0.3rem 0.0rem 0.0rem 0.3rem;
    }
    .blog-older-container-me-text-border {
        border-radius: 0.0rem 0.3rem 0.3rem 0.0rem;
    }
}


.blog-older-img-thumbnail {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
}
.blog-older-img-thumbnail > img {
    /*max-width: 100%;*/
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    overflow: hidden;
    object-fit: cover;
}
.blog-older-img-thumbnail div {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;

    opacity: 0;
    transition: all 0.3s ease;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    line-height: 100%;

    /*font-size: 30px;*/
    z-index: 2;
}
.blog-older-img-thumbnail-dark-75 div {
    background: rgba(0, 0, 0, 0.75);
}
.blog-older-img-thumbnail:hover > img {
    transform: scale(1.1);
}
.blog-older-img-thumbnail:hover > div {
    opacity: 1;
}
