@media screen and (max-width: 360px) {

}
@media  screen and (min-width: 360px) {

}
@media screen and (min-width: 576px) {

}
@media screen and (min-width: 768px) {

}
@media screen and (min-width: 992px) {
    .career-recruitment-card-height {
        height: 44rem;
    }
}
@media screen and (min-width: 1200px) {
    .career-recruitment-card-height {
        height: 39rem;
    }
}
@media screen and (min-width: 1400px) {
    .career-recruitment-card-height {
        height: 36rem;
    }
} 
