/* CUSTOM CLASSES >>> */

/* SEERVICES BUTTON shape and animation */
.services-button {
    transition: all 0.3s;
    height: 3rem;
    text-decoration: none;
    color: var(--white);
    padding-right: 1.0rem;
    padding-left: 1.0rem;
    background-color: var(--divelit-grey-90);

}
.services-button:hover {
    color: var(--divelit-lemon);
}

@media screen and (max-width: 360px) {
    .services-dropdown-margin {
        top: 0.75rem !important;
        margin: 0.0rem 0rem 1.25rem 0rem;
    }
}
@media  screen and (min-width: 360px) {
    .services-dropdown-margin {
        top: 0.75rem !important;
        margin: 0.0rem 0rem 1.25rem 0rem;
    }
}
@media screen and (min-width: 576px) {
    .services-dropdown-margin {
        top: 1.25rem !important;
        margin: 0.0rem 0rem 1.5rem 0rem;
    }
}
@media screen and (min-width: 768px) {
    .services-dropdown-margin {
        top: 1.25rem !important;
        margin: 0.0rem 0rem 2rem 0rem;
    }
}
@media screen and (min-width: 992px) {
    .services-dropdown-margin {
        top: 3rem !important;
        margin: 0rem 3.0rem 0rem 0rem;
    }
}
@media screen and (min-width: 1200px) {
    .services-dropdown-margin {
        top: 3rem !important;
        margin: 0rem 4.0rem 0rem 0rem;
    }
}
@media screen and (min-width: 1400px) {
    .services-dropdown-margin {
        top: 3rem !important;
        margin: 0rem 5.0rem 0rem 0rem;
    }
} 



/* LANG BUTTON shape and animation */
.lang-button {
    border-radius: 0.0rem 1.5rem 1.5rem 0.0rem;
	border: 0.05rem solid var(--divelit-lemon);
    transition: all 0.3s;
    height: 3rem;
    text-decoration: none;
    color: var(--divelit-lemon);
    padding-right: 1.5rem;
    padding-left: 1.0rem;
    background-color: var(--divelit-grey-90);

}
.lang-button:hover {
    background-color: var(--divelit-grey-80);
}

/* TODO - WIP ease-in and ease-out droipdown links animation*/
/*
.dropdown-item {

    border-radius: 0.3rem;
    animation-name: lang-btn-back !important;
    animation-duration: 3s;
}
.dropdown-item:hover {
    border-radius: 0.3rem;
    animation-name: lang-btn-forth !important;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}

@keyframes lang-btn-forth { 
  from { 
      background-color: var(--divelit-grey-80) !important;
  } 
  to { 
      background-color: var(--divelit-grey-50) !important;
  } 
} 
@keyframes lang-btn-back { 
  from { 
      background-color: var(--divelit-grey-50) !important;
  } 
  to { 
      background-color: var(--divelit-grey-80) !important;
  } 
}
*/


/* before hover */
/*
.lang-button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--divelit-grey-90);
    border-radius: 0.0rem 1.5rem 1.5rem 0.0rem;
    z-index: -2;
}*/
/* on hover */
/*
.lang-button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--divelit-grey-80);
    transition: all 0.3s;
    border-radius: 0.0rem 1.5rem 1.5rem 0.0rem;
    z-index: -1;
}*/
/*
.lang-button:hover:before {
    width: 100%;
}*/



.lang-button-active {
	color: var(--divelit-lemon);
}



@media screen and (max-width: 360px) {
    .lang-button-margin {
        margin: 1.0rem 0rem 1.5rem 0rem;
    }
}
@media  screen and (min-width: 360px) {
    .lang-button-margin {
        margin: 1.25rem 0rem 1.75rem 0rem;
    }
}
@media screen and (min-width: 576px) {
    .lang-button-margin {
        margin: 1.5rem 0rem 2.0rem 0rem;
    }
}
@media screen and (min-width: 768px) {
    .lang-button-margin {
        margin: 1.75rem 0rem 2.5rem 0rem;;
    }
}
@media screen and (min-width: 992px) {
    .lang-button-margin {
        margin: 0rem 3.0rem 0rem 0rem;
    }
}
@media screen and (min-width: 1200px) {
    .lang-button-margin {
        margin: 0rem 4.0rem 0rem 0rem;
    }
}
@media screen and (min-width: 1400px) {
    .lang-button-margin {
        margin: 0rem 5.0rem 0rem 0rem;
    }
} 
/* <<< CUSTOM CLASSES */
