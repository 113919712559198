/*@media screen and (max-width: 360px) {

}
@media screen and (min-width: 360px) {

}
@media screen and (min-width: 576px) {

}
@media screen and (max-width: 768px) {

}
@media screen and (min-width: 768px) {

}
@media screen and (min-width: 992px) {

}
@media screen and (min-width: 1200px) {

}
@media screen and (min-width: 1400px) {

};*/



.cybersec-services-card-link {
    color: var(--black);
    text-decoration: none;
}

.cybersec-services-card-link:hover {
    color: var(--divelit-grey-50);
    cursor: pointer;
    text-decoration: none;
}







.cybersec-services-card-link-2 {
    color: var(--black);
    text-decoration: none;

    animation-name: card-link-2-out;
    animation-duration: 0.3s;
}
.cybersec-services-card-link-2:hover {

    cursor: pointer;
    text-decoration: none;

    animation-name: card-link-2-in;
    animation-duration: 0.3s;
    animation-fill-mode: forwards; 
}
@keyframes card-link-2-in { 
    from { 
        /*color: var(--black);*/
        background-color: none;
    } 
    to { 
        color: var(--divelit-grey-05) !important;
        background-color: var(--divelit-plum-70) !important;
    } 
} 
@keyframes card-link-2-out { 
    from { 
        color: var(--divelit-grey-05) !important;
        background-color: var(--divelit-plum-70) !important;
    } 
    to { 
        /*color: var(--black);*/
        background-color: none;

    } 
} 



@media screen and (max-width: 360px) {
    .cybersec-services-down-icon-size {
        height: 2rem;
    }
}
@media screen and (min-width: 360px) {
    .cybersec-services-down-icon-size {
        height: 2.75rem;
    }
}
@media screen and (min-width: 576px) {
    .cybersec-services-down-icon-size {
        height: 3rem;
    }
}
@media screen and (min-width: 768px) {
    .cybersec-services-down-icon-size {
        height: 3.5rem;
    }
}
@media screen and (min-width: 992px) {

}
@media screen and (min-width: 1200px) {
    .cybersec-services-down-icon-size {
        height: 4rem;
    }
}
@media screen and (min-width: 1400px) {

};



