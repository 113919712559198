


@-moz-document url-prefix() {
    .ff-form-font {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    } 
}



@media screen and (max-width: 768px)
{
    .form-message-box
    {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    }
    .form-loading-animation {
        height: 50px;
        vertical-align: middle;
    }
    .form-input-style {
        padding-left: 1rem; 
        padding-right: 1rem;
        height: 3rem;
        width: 100%; 
        border: none; 
        border-radius: 0.3rem;
        background-color: var(--white);
    }
    .form-msg-input-style {
        padding-left: 1.5rem;
        padding-top: 1rem; 
        padding-right: 1.5rem;
        width: 100%; 
        border: none; 
        border-radius: 0.3rem;
        background-color: var(--white);
    }
}
@media screen and (min-width: 768px)
{
    .form-message-box
    {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
    }
    .form-loading-animation {
        height: 65px;
        vertical-align: middle;
    }
    .form-input-style {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        height: 4rem;
        width: 100%; 
        border: none; 
        border-radius: 0.3rem;
        background-color: var(--white);
    }
    .form-msg-input-style {
        padding-left: 1.5rem;
        padding-top: 1rem; 
        padding-right: 1.5rem;
        width: 100%; 
        border: none; 
        border-radius: 0.3rem;
        background-color: var(--white);
    }
}

/*.form-check-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}*/



.checkbox-container-lemon {
    display: block;
    position: relative;
    padding-left: 2.0rem;
    margin-right: 1rem;
    line-height: 2rem;
    width: fit-content;
    /*margin-top: auto;
    margin-bottom: auto;*/
    cursor: pointer;
    /*font-size: 22px;*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
  /* Hide the browser's default checkbox */
.checkbox-container-lemon input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
/* Create a custom checkbox */
.checkmark-lemon {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: var(--white);
    border-radius: 0.3rem
}


.checkbox-container-lemon input ~ .checkmark-lemon {
    animation-name: checkmark-lemon-back;
    animation-duration: 0.3s;
}
/* On mouse-over, add a grey background color */
.checkbox-container-lemon:hover input ~ .checkmark-lemon {
    animation-name: checkmark-lemon-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes checkmark-lemon-forth { 
    from { 
        /*background-color: var(--white);*/
    }
    to {
        background-color: var(--divelit-lemon-dark-80);
    } 
} 
@keyframes checkmark-lemon-back { 
    from { 
        background-color: var(--divelit-lemon-dark-80);
    } 
    to { 
        /*background-color: var(--white);*/
    } 
} 



/* When the checkbox is checked, add background */
.checkbox-container-lemon input:checked ~ .checkmark-lemon {
    background-color: var(--divelit-lemon);
}



/* Create the checkmark-lemon/indicator (hidden when not checked) */
.checkmark-lemon:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Show the checkmark-lemon when checked */
.checkbox-container-lemon input:checked ~ .checkmark-lemon:after {
    display: block;
}
  
/* Style the checkmark-lemon/indicator */
.checkbox-container-lemon .checkmark-lemon:after {
    left: 0.8rem;
    top: 0.4rem;
    width: 0.5rem;
    height: 1rem;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: green!important;
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 255, 0, 0.25)
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: #C8FFC8; 
}*/






.checkbox-container-plum {
    display: block;
    position: relative;
    padding-left: 2.0rem;
    margin-right: 1rem;
    line-height: 2rem;
    width: fit-content;
    /*margin-top: auto;
    margin-bottom: auto;*/
    cursor: pointer;
    /*font-size: 22px;*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
  /* Hide the browser's default checkbox */
.checkbox-container-plum input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
/* Create a custom checkbox */
.checkmark-plum {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: var(--white);
    border-radius: 0.3rem
}


.checkbox-container-plum input ~ .checkmark-plum {
    animation-name: checkmark-plum-back;
    animation-duration: 0.3s;
}
/* On mouse-over, add a grey background color */
.checkbox-container-plum:hover input ~ .checkmark-plum {
    animation-name: checkmark-plum-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes checkmark-plum-forth { 
    from { 
        /*background-color: var(--white);*/
    }
    to {
        background-color: var(--divelit-plum-dark-80);
    } 
} 
@keyframes checkmark-plum-back { 
    from { 
        background-color: var(--divelit-plum-dark-80);
    } 
    to { 
        /*background-color: var(--white);*/
    } 
} 



/* When the checkbox is checked, add background */
.checkbox-container-plum input:checked ~ .checkmark-plum {
    background-color: var(--divelit-plum);
}



/* Create the checkmark-plum/indicator (hidden when not checked) */
.checkmark-plum:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Show the checkmark-plum when checked */
.checkbox-container-plum input:checked ~ .checkmark-plum:after {
    display: block;
}
  
/* Style the checkmark-plum/indicator */
.checkbox-container-plum .checkmark-plum:after {
    left: 0.8rem;
    top: 0.4rem;
    width: 0.5rem;
    height: 1rem;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}