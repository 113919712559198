h1, h2, h3, h4, h5, .header-4-extra-extra-large, .header-extra-small {
    font-family: poppins-medium;
}
@media screen and (max-width: 360px) {
    .header-4-extra-extra-large {
        font-size: 3.433rem;
    }
    h1 {
        font-size: 2.747rem;
    }
    h2 {
        font-size: 2.197rem;
    }
    h3 {
        font-size: 1.758rem;
    }
    h4 {
        font-size: 1.406rem;
    }
    h5 {
        font-size: 1.125rem;
    }
    .header-extra-small {
        font-size: 1.125rem;
    }

}
@media  screen and (min-width: 360px) {
    .header-4-extra-extra-large {
        font-size: 3.815rem;
    }
    h1 {
        font-size: 3.052rem;
    }
    h2 {
        font-size: 2.441rem;
    }
    h3 {
        font-size: 1.953rem;
    }
    h4 {
        font-size: 1.563rem;
    }
    h5 {
        font-size: 1.250rem;
    }
    .header-extra-small {
        font-size: 1.250rem;
    }
}
@media screen and (min-width: 576px) {
    .header-4-extra-extra-large {
        font-size: 4.196rem;
    }
    h1 {
        font-size: 3.357rem;
    }
    h2 {
        font-size: 2.686rem;
    }
    h3 {
        font-size: 2.148rem;
    }
    h4 {
        font-size: 1.719rem;
    }
    h5 {
        font-size: 1.375rem;
    }
    .header-extra-small {
        font-size: 1.375rem;
    }
}
@media screen and (min-width: 768px) {
    .header-4-extra-extra-large {
        font-size: 4.578rem;
    }
    h1 {
        font-size: 3.662rem;
    }
    h2 {
        font-size: 2.930rem;
    }
    h3 {
        font-size: 2.344rem;
    }
    h4 {
        font-size: 1.875rem;
    }
    h5 {
        font-size: 1.500rem;
    }
    .header-extra-small {
        font-size: 1.500rem;
    }
}
@media screen and (min-width: 992px) {
    .header-4-extra-extra-large {
        font-size: 4.957rem;
    }
    h1 {
        font-size: 3.967rem;
    }
    h2 {
        font-size: 3.174rem;
    }
    h3 {
        font-size: 2.539rem;
    }
    h4 {
        font-size: 2.031rem;
    }
    h5 {
        font-size: 1.625rem;
    }
    .header-extra-small {
        font-size: 1.625rem;
    }
}
@media screen and (min-width: 1200px) {
    .header-4-extra-extra-large {
        font-size: 5.341rem;
    }
    h1 {
        font-size: 4.272rem;
    }
    h2 {
        font-size: 3.418rem;
    }
    h3 {
        font-size: 2.734rem;
    }
    h4 {
        font-size: 2.188rem;
    }
    h5 {
        font-size: 1.750rem;
    }
    .header-extra-small {
        font-size: 1.750rem;
    }
}
@media screen and (min-width: 1400px) {
    .header-4-extra-extra-large {
        font-size: 5.722rem;
    }
    h1 {
        font-size: 4.578rem;
    }
    h2 {
        font-size: 3.662rem;
    }
    h3 {
        font-size: 2.930rem;
    }
    h4 {
        font-size: 2.344rem;
    }
    h5 {
        font-size: 1.875rem;
    }
    .header-extra-small {
        font-size: 1.875rem;
    }
}
