.software-partners-img {
    filter: brightness(0) invert(1);
}

.software-partners-linet-img {
    filter: brightness(0) invert(1);
}
@media screen and (max-width: 768px) {
    .software-partners-img {
        height: 2.5rem;
    }
    .software-partners-linet-img {
        height: 1.6rem;
    }

}
@media screen and (min-width: 768px) {
    .software-partners-img {
        height: 2.5rem;
    }
    .software-partners-linet-img {
        height: 1.6rem;
    }

}
@media screen and (min-width: 992px) {
    .software-partners-img {
        height: 2.75rem;
    }
    .software-partners-linet-img {
        height: 1.8rem;
    }
}
@media screen and (min-width: 1200px) {
    .software-partners-img {
        height: 3rem;
    }
    .software-partners-linet-img {
        height: 2.0rem;
    }

}
@media screen and (min-width: 1400px) {
    .software-partners-img {
        height: 3rem;
    }
    .software-partners-linet-img {
        height: 2.0rem;
    }

}

.our-services-partners-img {
    filter: brightness(0) invert(0);
}

.our-services-partners-linet-img {
    filter: brightness(0) invert(0);
}


@media screen and (max-width: 768px) {
    .software-hero-bg-img {
        background-color: var(--divelit-grey-90);
        max-width: 100%;
        height: auto;
    
        max-width: 100%;
        display: block;
        position: relative;
        z-index: 1;
        /*border-bottom: 1px solid var(--divelit-grey-80);*/
    }
    .software-hero-bg-img::after {
        content: "";
        /*filter: brightness(60%);*/

        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.6) 10%, rgba(26, 26, 26, 0.60) 40%, rgba(26, 26, 26, 0.7) 100%), linear-gradient(0deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 50%, rgba(26, 26, 26, 0.65) 60%, rgba(26, 26, 26, 0.65) 100%), url("../../images/pages/software/divelit_code_screen.webp");
        background-size: 50rem;
        background-position: left top;
        background-repeat: no-repeat;

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        
    }
}
@media screen and (min-width: 768px) {

    .software-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.6) 10%, rgba(26, 26, 26, 0.5) 40%, rgba(26, 26, 26, 0.3) 100%), linear-gradient(0deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 50%, rgba(26, 26, 26, 0.5) 60%, rgba(26, 26, 26, 0.3) 100%), url("../../images/pages/software/divelit_code_screen.webp");
        background-size: 35rem;
        background-position: right top;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 992px) {
    .software-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.5) 10%, rgba(26, 26, 26, 0.3) 40%, rgba(26, 26, 26, 0.2) 100%), linear-gradient(0deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 50%, rgba(26, 26, 26, 0.5) 60%, rgba(26, 26, 26, 0.2) 100%), url("../../images/pages/software/divelit_code_screen.webp");
        background-size: 40rem;
        background-position: right top;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 1200px) {
    .software-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.5) 10%, rgba(26, 26, 26, 0.3) 40%, rgba(26, 26, 26, 0.2) 100%), linear-gradient(0deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 40%, rgba(26, 26, 26, 0.5) 60%, rgba(26, 26, 26, 0.2) 100%), url("../../images/pages/software/divelit_code_screen.webp");
        background-size: 45rem;
        background-position: right top;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 1400px) {
    .software-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.5) 10%, rgba(26, 26, 26, 0.3) 40%, rgba(26, 26, 26, 0.2) 100%), linear-gradient(0deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 20%, rgba(26, 26, 26, 0.5) 60%, rgba(26, 26, 26, 0.2) 100%), url("../../images/pages/software/divelit_code_screen.webp");
        background-size: 60rem;
        background-position: right top;
        background-repeat: no-repeat;
    }
}


.software-address-section-bg {
    background: linear-gradient(0deg, var(--divelit-grey-05) 0%, var(--divelit-grey-05) 35%, rgba(26, 26, 26, 1) 20%, rgba(26, 26, 26, 0.0) 100%);
}
