.blog-latest-read-more-btn {
    text-decoration: none;
    transition: 0.3s;
}
.blog-latest-read-more-btn:hover {
    text-decoration: underline;
    color: var(--divelit-lemon);
}



.blog-latest-header {
    text-decoration: none;
    transition: 0.3s;
    display: inline-block;
}
.blog-latest-header:hover {
    text-decoration: underline;
    color: var(--white);

}



.blog-latest-categories li {
    display: inline;
}
.blog-latest-categories li a {
    color: var(--white);
    text-decoration: none;
    transition: 0.3s;
}
.blog-latest-categories li a:hover {
    color: var(--divelit-lemon);
    text-decoration: underline;
}



.blog-latest-section {
    background: var(--divelit-grey-90);
    display: block;
    position: relative;
    z-index: 1;
    z-index: 1;



}
.blog-latest-section::after{
    
    content: "";
    /*background: url("../icons/rency/square_lime.svg");*/
    /*background: url("../logos/divelit/jen-symbol/divelit-symbol-barevné-rgb.svg"), url("../logos/divelit/jen-symbol/divelit-symbol-barevné-rgb.svg");
    background-position: -15rem 17rem, right top;
    background-repeat: no-repeat;
    background-size: 30rem;*/

    /*background: url("../logos/divelit/jen-symbol/divelit-symbol-barevné-rgb.svg");
    background-position: -10rem 40rem;
    height: 100vw;
    transform: rotate(90deg);
    filter: grayscale(100);
    background-repeat: no-repeat;
    background-size: 30rem;*/

/*
    background: url("../background_images/career_vector.svg");
    background-size: contain;
    filter:opacity(0.4);*/
    /*background-repeat: no-repeat;*/


    /*background: url("../icons/rency/square_lime.svg"), url("../background_images/career_vector_v2.svg");*/
    /*filter: brightness(60%);*/
    /*background-size: 30rem, auto;
    background-position: -20rem, 100rem;
    background-repeat: no-repeat;*/

    /*content: "";
    background: url("../logos/divelit/jen-symbol/divelit-symbol-barevné-rgb.svg"), url("../logos/divelit/jen-symbol/divelit-symbol-barevné-rgb.svg");
    background-size: 50rem, 50rem;
    background-position: left, right;
    background-repeat: no-repeat;*/

    
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}



@media screen and (max-width: 360px) {
    .blog-latest-author-profile-img {
        width: 2em;
        height: 2em;
        border-radius: 100%;
    }
    .blog-latest-img {
        height: 10rem;
    }
}
@media  screen and (min-width: 360px) {
    .blog-latest-author-profile-img {
        width: 2em;
        height: 2em;
        border-radius: 100%;
    }
    .blog-latest-img {
        height: 13.5rem;
    }
}
@media screen and (min-width: 576px) {
    .blog-latest-author-profile-img {
        width: 2em;
        height: 2em;
        border-radius: 100%;
    }
    .blog-latest-img {
        height: 17rem;
    }
}
@media screen and (max-width: 768px) {

}
@media screen and (min-width: 768px) {
    .blog-latest-author-profile-img {
        width: 2.5em;
        height: 2.5em;
        border-radius: 100%;
    }
    .blog-latest-img {
        height: 17rem;
    }
}
@media screen and (min-width: 992px) {
    .blog-latest-author-profile-img {
        width: 3em;
        height: 3em;
        border-radius: 100%;
    }
    .blog-latest-img {
        height: 22rem;
    }
}
@media screen and (min-width: 1200px) {
    .blog-latest-author-profile-img {
        width: 3em;
        height: 3em;
        border-radius: 100%;
    }
    .blog-latest-img {
        height: 25rem;
    }
}
@media screen and (min-width: 1400px) {
    .blog-latest-author-profile-img {
        width: 3em;
        height: 3em;
        border-radius: 100%;
    }
    .blog-latest-img {
        height: 30rem;
    }
} 



@media screen and (max-width: 992px) {
    .blog-latest-img-border {
        border-radius: 0.3rem;
    }
}
@media screen and (min-width: 992px) {
    .blog-latest-img-border {
        border-radius: 0.3rem;
    }
}


@media screen and (max-width: 768px) {
    .blog-latest-img-btn {
        border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
        background-color: rgb(200, 204, 38);
        height: 3rem;
        width: fit-content;
        padding-right: 1.5rem;
        padding-left: 1.25rem;
        line-height: 3rem;
        
        color: var(--black);
        font-family: poppins-medium;
     }
}
/* Desktop */
@media screen and (min-width: 768px) {
    .blog-latest-img-btn {
        border-radius: 0.3rem 2rem 2rem 0.3rem;
        background-color: rgb(200, 204, 38);
        height: 4rem;
        width: fit-content;
        padding-right: 2.0rem;
        padding-left: 1.25rem;
        line-height: 4rem;
    
        color: var(--black);
        font-family: poppins-medium;
     }
}
