nav.navbar{
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

nav.navbar.transparent {
    background-color:rgba(0,0,0,0);
}

@media screen and (max-width:  768px) {
    nav.navbar.transparent {
        background-color:rgba(0,0,0,0.8);
    }
}
