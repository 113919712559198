/* the value that is being deducted corresponds to footer height mesure by developer tools in browser*/
@media screen and (max-width: 576px) {
    .page-404-height {
        min-height: 25em;
        /*height: calc(100vh - (431px));*/
    }
}
@media screen and (min-width: 576px) {
    .page-404-height {
        min-height: 25em;
        /*height: calc(100vh - (457px));*/
    }
}
@media screen and (min-width: 768px) {
    .page-404-height {
        min-height: 25em;
        /*height: calc(100vh - (269px));*/
    }
}
@media screen and (min-width: 992px) {
    .page-404-height {
        min-height: 30em;
        /*height: calc(100vh - (274px));*/
    }
}
@media screen and (min-width: 1200px) {
    .page-404-height {
        min-height: 30em;
        /*height: calc(100vh - (288px));*/
    }
}
@media screen and (min-width: 1400px) {
    .page-404-height {
        min-height: 35em;
        /*height: calc(100vh - (302px));*/
    }
} 
