/* NAVBAR is hiding just on mobile screens, on bigger screens (992px and up) it stays on top of window without hiding no matter how much user schrolls*/
/*@media screen and (max-width: 576px) {
    .navbar-scrolled-down {
        transform:translateY(-100%); transition: all 0.3s ease-in-out;
      }
      .navbar-scrolled-up {
        transform:translateY(0); transition: all 0.3s ease-in-out;
      }
}*/
/*@media screen and (max-width: 576px) {*/

/* CUSTOM CLASSES >>> */
.navbar-active {
    color: var(--divelit-lemon) !important;
}
@media screen and (max-width: 992px) {
    .navbar-scrolled-down {
        transform:translateY(-100%); transition: all 0.3s ease-in-out;
      }
      .navbar-scrolled-up {
        transform:translateY(0); transition: all 0.3s ease-in-out;
      }
      .navbar-mobile-separator {
        border: solid 0.05rem var(--divelit-grey-80);
        margin: 0;
        width: 10rem;
    }
}
@media screen and (min-width: 992px) {
    .navbar-scrolled-down {
        transform: unset;
    }
    .navbar-scrolled-up {
        transform: unset;
    }
    .navbar-mobile-separator {
        display: none
    }
}
/* <<< CUSTOM CLASSES */



/* BOOTSTRAP CLASSES customization >>> */
.nav-link {
    animation-name: nav-link-back;
    animation-duration: 0.3s;
    color: var(--white);
}
.nav-link:hover {
    animation-name: nav-link-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes nav-link-forth { 
    from {
        color: var(--white);
    } 
    to { 
        color: var(--divelit-lemon);
    } 
} 
@keyframes nav-link-back { 
    from { 
        color: var(--divelit-lemon);
    } 
    to {
        color: var(--white);
    } 
} 
.navbar-dark {
    background-color: var(--divelit-grey-90);
    border-bottom: 1px solid var(--divelit-grey-80);
}
@media screen and (max-width: 360px) {
    .navbar {
        min-height: 4.0rem;
    }
    .navbar-brand {
        height: 1.5rem;
        /* those 2 margins together plus height of the .navbar-brand should give total of min-height of the .navbar class 
        - othervise mobile menu will have no whitespace around logo when uncollapsed */
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;
        padding: 0rem 0rem 0rem 0rem;
        margin-left: 1rem;
    }
    .nav-item {
        width: 8rem;
        height: 3rem;
        text-align: center; 
    }
    .nav-link {
        line-height: 3rem;
        padding: 0;
    }
}
@media  screen and (min-width: 360px) {
    .navbar {
        min-height: 4.5rem;
    }
    .navbar-brand {
        height: 1.75rem;
        margin-bottom: 1.375rem;
        margin-top: 1.375rem;
        /*line-height: 2.5em;*/
        padding: 0rem 0rem 0rem 0rem;
        margin-left: 1.5rem;
    }
    .nav-item {
        width: 8rem;
        height: 3rem;
        text-align: center; 
    }
    .nav-link {
        line-height: 3rem;
        padding: 0;
    }
}
@media screen and (min-width: 576px) {
    .navbar {
        min-height: 5.0rem;
    }
    .navbar-brand {
        height: 1.75rem;
        margin-bottom: 1.625rem;
        margin-top: 1.625rem;
        /*line-height: 2.5em;*/
        padding: 0rem 0rem 0rem 0rem;
        margin-left: 2.0rem;
    }
    .nav-item {
        width: 8rem;
        height: 3rem;
        text-align: center; 
    }
    .nav-link {
        line-height: 3rem;
        padding: 0;
    }
}
@media screen and (min-width: 768px) {
    .navbar {
        min-height: 5.5rem;
    }
    .navbar-brand {
        height: 2.00rem;
        /*line-height: 2.5em;*/
        margin-bottom: 1.75rem;
        margin-top: 1.75rem;
        padding: 0rem 0rem 0rem 0rem;
        margin-left: 2.5rem;
    }
    .nav-item {
        width: 8rem;
        height: 3rem;
        text-align: center; 
    }
    .nav-link {
        line-height: 3rem;
        padding: 0;
    }

}
@media screen and (min-width: 992px) {
    .navbar {
        min-height: 6rem;
    }
    .navbar-brand {
        height: 2.25rem;
        /*line-height: 2.5em;*/
        padding: 0rem 0rem 0rem 0rem;
        margin-left: 3.0rem;
    }
    .nav-item {
        width: auto;
        margin-right: 1.75rem;
        height: fit-content;
        text-align: center;
    }
    .nav-dropdown-item {
        width: auto;
        margin-right: 1.75rem;
        height: fit-content;
        text-align: center;
    }
    .nav-link {
        line-height: normal;
        padding: 0
    }

}
@media screen and (min-width: 1200px) {
    .navbar {
        min-height: 6.5rem;
    }
    .navbar-brand {
        height: 2.5rem;
        /*line-height: 2.5em;*/
        padding: 0rem 0rem 0rem 0rem;
        margin-left: 4.0rem;
    }
    .nav-item {
        margin-right: 2.5rem;
    }
    .nav-dropdown-item {
        margin-right: 2.5rem;
    }

}
@media screen and (min-width: 1400px) {
    .navbar {
        min-height: 7.0rem;
    }
    .navbar-brand {
        height: 2.75rem;
        /*line-height: 2.5em;*/
        padding: 0rem 0rem 0rem 0rem;
        margin-left: 5rem;
    }
    .nav-item {
        margin-right: 3.0rem;
    }
    .nav-dropdown-item {
        margin-right: 3.0rem;
    }

} 
/* <<< BOOTSTRAP CLASSES customization */


/* BODY padding size is set according to NAVBAR height */
/* this way page content won't be displayed hidden behind NAVBAR */
@media screen and (max-width: 360px) {
    .navbar-size-compensation {
        padding-top: +4rem;    
    }
    .home-hero-bg-img {
        margin-top: -4rem;
    }
}
@media  screen and (min-width: 360px) {
    .navbar-size-compensation {
        padding-top: +4.5rem;    
    }
    .home-hero-bg-img {
        margin-top: -4.5rem;
    }
}
@media screen and (min-width: 576px) {
    .navbar-size-compensation {
        padding-top: +5rem;    
    }
    .home-hero-bg-img {
        margin-top: -5rem;
    }
}
@media screen and (min-width: 768px) {
    .navbar-size-compensation {
        padding-top: +5.5rem;    
    }
     .home-hero-bg-img {
        margin-top: -5.5rem;
    }
}
@media screen and (min-width: 992px) {
    .navbar-size-compensation {
        padding-top: +6rem;    
    }
    #experts {
        position: relative;
        top:-6rem;
    }
    #form_section {
        position: relative;
        top:-6rem;
    }
    .home-hero-bg-img {
        margin-top: -7rem;
    }
}
@media screen and (min-width: 1200px) {
    .navbar-size-compensation {
        padding-top: +6.5rem;    
    }
    #experts {
        position: relative;
        top:-6.5rem;
    }
    #form_section {
        position: relative;
        top:-6.5rem;
    }
    .home-hero-bg-img {
        margin-top: -6.5rem;
    }
}
@media screen and (min-width: 1400px) {
    .navbar-size-compensation {
        padding-top: +7rem;    
    }
    #experts {
        position: relative;
        top:-7rem;
    }
    #form_section {
        position: relative;
        top:-7rem;
    }
    .home-hero-bg-img {
        margin-top: -7rem;
    }
}
