.career-team-laser-game-header {
    z-index: 2;
    position: relative;
}
.career-team-laser-game-hr {
    z-index: 3;
    position: relative;
}
.career-team-laser-game-img {
    z-index: 2;
    position: relative;
    object-fit: cover;
}
@media screen and (max-width: 360px) {
    .career-team-laser-game-img {
        height: 52vw;
        width: 100%;
    }
}
@media  screen and (min-width: 360px) {
    .career-team-laser-game-img {
        height: 60vw;
        width: 100%;
    }
}
@media screen and (min-width: 576px) {
    .career-team-laser-game-img {
        height: 50vw;
        width: 100%;
    }
}
@media screen and (min-width: 768px) {
    .career-team-laser-game-header {
        top: 6.5rem;
        left: 29rem;
    }
    .career-team-laser-game-hr {
        top: 5rem;
        left: 19rem;
    }
    .career-team-laser-game-img {
        height: 18rem;
        width: auto;
    }
}
@media screen and (min-width: 992px) {
    .career-team-laser-game-header {
        top: 9rem;
        left: 40rem;
    }
    .career-team-laser-game-hr {
        top: 7rem;
        left: 27rem;
    }
    .career-team-laser-game-img {
        height: 23rem;
    }
}
@media screen and (min-width: 1200px) {
    .career-team-laser-game-header {
        top: 9rem;
        left: 49rem;
    }
    .career-team-laser-game-hr {
        top: 7rem;
        left: 33rem;
    }
    .career-team-laser-game-img {
        height: 28rem;
    }
}
@media screen and (min-width: 1400px) {
    .career-team-laser-game-header {
        top: 9rem;
        left: 55rem;
    }
    .career-team-laser-game-hr {
        top: 7rem;
        left: 37rem;
    }
    .career-team-laser-game-img {
        height: 32rem;
    }
}



@media screen and (min-width: 768px) {
    .career-team-logo-section {
        top: -15rem;
        position: relative;
    }
}
@media screen and (min-width: 992px) {
    .career-team-logo-section {
        top: -25rem;
        position: relative;
    }
}
@media screen and (min-width: 1200px) {
    .career-team-logo-section {
        top: -25rem;
        position: relative;
    }
}
@media screen and (min-width: 1400px) {
    .career-team-logo-section {
        top: -33rem;
        position: relative;
    }
}



.career-team-brainstorming-img {
    object-fit: cover;
}
.career-team-brainstorming-hr {
   z-index: 1;
   position: relative;
}
.career-team-brainstorming-header {
    z-index: 1;
    position: relative;
}
@media screen and (max-width: 360px) {
    .career-team-brainstorming-img {
        height: 70vw;
        width: 100%;
    }
    .career-team-brainstorming-header {
        top: 0rem;
        left: 0rem;
    }
}
@media  screen and (min-width: 360px) {
    .career-team-brainstorming-img {
        height: 60vw;
        width: 100%;

    }
    .career-team-brainstorming-header {
        top: 0rem;
        left: 0rem;
    }
}
@media screen and (min-width: 576px) {
    .career-team-brainstorming-img {
        height: 55vw;
        width: 100%;

    }
    .career-team-brainstorming-header {
        top: 0rem;
        left: 0rem;
    }
}
@media screen and (min-width: 768px) {
    .career-team-brainstorming-img {
        height: 18rem;
        width: auto;

    }
    .career-team-brainstorming-hr {
        top: -12rem;
        left: +18.5rem;
    }
    .career-team-brainstorming-header {
        top: -9rem;
        left: +28rem;
    }
}
@media screen and (min-width: 992px) {
    .career-team-brainstorming-img {
        height: 22rem;
    }
    .career-team-brainstorming-hr {
        top: -15rem;
        left: +24rem;
    }
    .career-team-brainstorming-header {
        top: -12rem;
        left: +37rem;
    }
}
@media screen and (min-width: 1200px) {
    .career-team-brainstorming-img {
        height: 25rem;
    }
    .career-team-brainstorming-hr {
        top: -17rem;
        left: +30rem;
    }
    .career-team-brainstorming-header {
        top: -14rem;
        left: +47rem;
    }
}
@media screen and (min-width: 1400px) {
    .career-team-brainstorming-img {
        height: 30rem;
    }
    .career-team-brainstorming-hr {
        top: -15rem;
        left: +33rem;
    }
    .career-team-brainstorming-header {
        top: -11.5rem;
        left: +50rem;
    }
}
/*style={{position: "relative", top: "-30rem", left:"+55rem", width: "fit-content"}}*/





.career-team-gingerbread-img {
    object-fit: cover;
}
.career-team-gingerbread-hr {
   z-index: 2;
   position: relative;
}
.career-team-gingerbread-header {
    z-index: 2;
    position: relative;
}
@media screen and (max-width: 360px) {
    .career-team-gingerbread-img {
        height: 50vw;
        width: 100%;
    }
    .career-team-gingerbread-header {
        top: 0rem;
        left: 0rem;
        margin-left: auto;
        margin-right: 0;
    }
}
@media  screen and (min-width: 360px) {
    .career-team-gingerbread-img {
        height: 45vw;
        width: 100%;

    }
    .career-team-gingerbread-header {
        top: 0rem;
        left: 0rem;
        margin-left: auto;
        margin-right: 0;
    }
}
@media screen and (min-width: 576px) {
    .career-team-gingerbread-img {
        height: 45vw;
        width: 100%;

    }
    .career-team-gingerbread-header {
        top: 0rem;
        left: 0rem;
        margin-left: auto;
        margin-right: 0;
    }

}
@media screen and (min-width: 768px) {
    .career-team-gingerbread-img {
        height: 15rem;
        width: auto;
    }
    .career-team-gingerbread-hr {
        top: +12rem;
        left: 16rem;
    }
    .career-team-gingerbread-header {
        top: 15rem;
        left: +2rem;
        margin-left: 0;
        margin-right: 0;
    }
    .career-team-gingerbread-md-mt {
        position: relative;
        top: -15rem;
    }
    .career-team-container {
        /*margin-bottom: -17rem;*/
        height: 90rem;
    }
}
@media screen and (min-width: 992px) {
    .career-team-gingerbread-img {
        height: 20rem;
    }
    .career-team-gingerbread-hr {
        top: +17rem;
        left: 21rem;
    }
    .career-team-gingerbread-header {
        top: 20rem;
        left: +6rem;
    }
    .career-team-gingerbread-md-mt {
        position: relative;
        top: -22rem;
    }
    .career-team-container {
        /*margin-bottom: -30rem;*/
        height: 110rem;
    }
}
@media screen and (min-width: 1200px) {
    .career-team-gingerbread-img {
        height: 22rem;
    }
    .career-team-gingerbread-hr {
        top: +19rem;
        left: 27rem;
    }
    .career-team-gingerbread-header {
        top: 22rem;
        left: +10rem;
    }
    .career-team-gingerbread-md-mt {
        position: relative;
        top: -24rem;
    }
    .career-team-container {
        /*margin-bottom: -30rem;*/
        height: 125rem;
    }
}
@media screen and (min-width: 1400px) {
    .career-team-gingerbread-img {
        height: 27rem;
    }
    .career-team-gingerbread-hr {
        top: +23rem;
        left: 33rem;
    }
    .career-team-gingerbread-header {
        top: 26rem;
        left: +15rem;
    }
    .career-team-gingerbread-md-mt {
        position: relative;
        top: -28rem;
    }
    .career-team-container {
        /*margin-bottom: -35rem;*/
        height: 145rem;
    }
}
