.embedded-sw-tile-position {
    z-index: 2;       
}
@media screen and (max-width: 360px) {
    .embedded-sw-tile-position {
        margin-top: -3rem;
        margin-right: auto;
        margin-left: auto;
    }
}
@media  screen and (min-width: 360px) {
    .embedded-sw-tile-position {
        margin-top: -3rem;
        margin-right: auto;
        margin-left: auto;
    }
}
@media screen and (min-width: 576px) {
    .embedded-sw-tile-position {
        margin-top: -2.5rem;
        margin-right: auto;
        margin-left: auto;
    }
}
@media screen and (min-width: 768px) {
    .embedded-sw-tile-position {
        margin-top: -3rem;
        margin-right: auto;
        margin-left: auto;
    }
}
@media screen and (min-width: 992px) {
    .embedded-sw-tile-position {
        margin-top: -5rem;
        margin-left: +4rem;
    }
}
@media screen and (min-width: 1200px) {
    .embedded-sw-tile-position {
        margin-top: -5rem;
        margin-left: +8rem; 
    }
}
@media screen and (min-width: 1400px) {
    .embedded-sw-tile-position {
        margin-top: -5rem;
        margin-left: +10rem;
    }
}
