/* embedded-hw img hover effect - no link */
.embedded-img-thumbnail {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    /*cursor: pointer;*/
}
.embedded-img-thumbnail > img {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    overflow: hidden;
    object-fit: cover;
}
.embedded-img-thumbnail div {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.3s ease;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    line-height: 100%;
    z-index: 2;
}
.embedded-img-thumbnail-dark-75 div {
    background: rgba(0, 0, 0, 0.75);
}
.embedded-img-thumbnail:hover > img {
    transform: scale(1.1);
}
.embedded-img-thumbnail:hover > div {
    opacity: 1;
}



@media screen and (max-width: 768px) {
    .embedded-hero-bg-img {
        background-color: var(--divelit-grey-90);
        max-width: 100%;
        height: auto;
    
        max-width: 100%;
        display: block;
        position: relative;
        z-index: 1;
        /*border-bottom: 1px solid var(--divelit-grey-80);*/
    }
    .embedded-hero-bg-img::after {
        content: "";
        /*filter: brightness(60%);*/

        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.40) 10%, rgba(26, 26, 26, 0.40) 40%, rgba(26, 26, 26, 0.5) 100%), linear-gradient(45deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 20%, rgba(26, 26, 26, 0.5) 60%, rgba(26, 26, 26, 0.5) 100%), url("../../images/pages/embedded/embedded_img.webp");
        background-size: 50rem;
        background-position: left 80%;
        background-repeat: no-repeat;

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        
    }
}
@media screen and (min-width: 768px) {

    .embedded-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.6) 10%, rgba(26, 26, 26, 0.5) 40%, rgba(26, 26, 26, 0.3) 100%), linear-gradient(45deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 20%, rgba(26, 26, 26, 0.5) 60%, rgba(26, 26, 26, 0.3) 100%), url("../../images/pages/embedded/embedded_img.webp");
        background-size: 35rem;
        background-position: 120% 80%;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 992px) {
    .embedded-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.5) 10%, rgba(26, 26, 26, 0.3) 20%, rgba(26, 26, 26, 0.2) 100%), linear-gradient(45deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 20%, rgba(26, 26, 26, 0.5) 50%, rgba(26, 26, 26, 0.2) 100%), url("../../images/pages/embedded/embedded_img.webp");
        background-size: 35rem;
        background-position: 120% 80%;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 1200px) {
    .embedded-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.5) 10%, rgba(26, 26, 26, 0.3) 40%, rgba(26, 26, 26, 0.2) 100%), linear-gradient(45deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 20%, rgba(26, 26, 26, 0.5) 60%, rgba(26, 26, 26, 0.2) 100%), url("../../images/pages/embedded/embedded_img.webp");
        background-size: 35rem;
        background-position: right 80%;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 1400px) {
    .embedded-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.5) 10%, rgba(26, 26, 26, 0.3) 40%, rgba(26, 26, 26, 0.2) 100%), linear-gradient(45deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 20%, rgba(26, 26, 26, 0.5) 60%, rgba(26, 26, 26, 0.2) 100%), url("../../images/pages/embedded/embedded_img.webp");
        background-size: 50rem;
        background-position: right 80%;
        background-repeat: no-repeat;
    }
}
