@media screen and (max-width: 768px) {
    .cybersec-hero-bg-img {
        background-color: var(--divelit-grey-90);
        max-width: 100%;
        height: auto;
    
        max-width: 100%;
        display: block;
        position: relative;
        z-index: 1;
        /*border-bottom: 1px solid var(--divelit-grey-80);*/
    }
    .cybersec-hero-bg-img::after {
        content: "";
        /*filter: brightness(60%);*/

        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.40) 10%, rgba(26, 26, 26, 0.40) 40%, rgba(26, 26, 26, 0.5) 100%), linear-gradient(45deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 20%, rgba(26, 26, 26, 0.5) 60%, rgba(26, 26, 26, 0.5) 100%), url("../../images/pages/cybersec/server_room.webp");
        background-size: 55rem;
        background-position: left 80%;
        background-repeat: no-repeat;

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        
    }
}
@media screen and (min-width: 768px) {

    .cybersec-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.6) 10%, rgba(26, 26, 26, 0.5) 40%, rgba(26, 26, 26, 0.3) 100%), linear-gradient(45deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 20%, rgba(26, 26, 26, 0.5) 60%, rgba(26, 26, 26, 0.3) 100%), url("../../images/pages/cybersec/server_room.webp");
        background-size: 65rem;
        background-position: 30% 80%;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 992px) {
    .cybersec-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.5) 10%, rgba(26, 26, 26, 0.3) 20%, rgba(26, 26, 26, 0.2) 100%), linear-gradient(45deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 20%, rgba(26, 26, 26, 0.5) 50%, rgba(26, 26, 26, 0.2) 100%), url("../../images/pages/cybersec/server_room.webp");
        background-size: 60rem;
        background-position: 120% 80%;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 1200px) {
    .cybersec-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.5) 10%, rgba(26, 26, 26, 0.3) 40%, rgba(26, 26, 26, 0.2) 100%), linear-gradient(45deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 20%, rgba(26, 26, 26, 0.5) 60%, rgba(26, 26, 26, 0.2) 100%), url("../../images/pages/cybersec/server_room.webp");
        background-size: 60rem;
        background-position: right 80%;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 1400px) {
    .cybersec-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0.5) 10%, rgba(26, 26, 26, 0.3) 40%, rgba(26, 26, 26, 0.2) 100%), linear-gradient(45deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 20%, rgba(26, 26, 26, 0.5) 60%, rgba(26, 26, 26, 0.2) 100%), url("../../images/pages/cybersec/server_room.webp");
        background-size: 60rem;
        background-position: right 80%;
        background-repeat: no-repeat;
    }
}
