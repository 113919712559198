/*@media screen and (max-width: 360px) {

}
@media screen and (min-width: 360px) {

}
@media screen and (min-width: 576px) {

}
@media screen and (max-width: 768px) {

}
@media screen and (min-width: 768px) {

}
@media screen and (min-width: 992px) {

}
@media screen and (min-width: 1200px) {

}
@media screen and (min-width: 1400px) {

};*/


@media screen and (max-width: 360px) {
    .cybersec-training-moto-quotation-bottom {
        font-size: 4rem;
        line-height: 4rem;
    }
    .cybersec-training-moto-quotation-top {
        font-size: 4rem;
        line-height: 2rem;
    }
}
@media screen and (min-width: 360px) {
    .cybersec-training-moto-quotation-bottom {
        font-size: 7rem;
        line-height: 6rem;
    }
    .cybersec-training-moto-quotation-top {
        font-size: 7rem;
        line-height: 3rem;
    }
}
@media screen and (min-width: 768px) {
    .cybersec-training-moto-quotation-bottom {
        font-size: 8rem;
        line-height: 7rem;
    }
    .cybersec-training-moto-quotation-top {
        font-size: 8rem;
        line-height: 3rem;
    }
}
@media screen and (min-width: 992px) {
    .cybersec-training-moto-quotation-bottom {
        font-size: 10rem;
        line-height: 8rem;
    }
    .cybersec-training-moto-quotation-top {
        font-size: 10rem;
        line-height: 5rem;
    }
}

.box {
    width: 500px; 
    height: 200px;  
    border: dashed 15px var(--divelit-lemon);
    border-color: var(--divelit-plum) transparent transparent transparent;
    border-radius: 50%/100px 100px 0 0;
  }


@media screen and (max-width: 360px) {
    .cybersec-training-moto-icon-size{
        width: 4rem;
        left: -3rem;
        top: -1rem
    }    
}
@media screen and (min-width: 360px) {
    .cybersec-training-moto-icon-size{
        width: 4rem;
        left: -3rem;
        top: -1rem
    }
}
@media screen and (min-width: 768px) {
    .cybersec-training-moto-icon-size{
        width: 5rem;
        left: -3rem;
        top: -2rem
    }
}
@media screen and (min-width: 992px) {
    .cybersec-training-moto-icon-size{
        width: 5rem;
        left: -3rem;
        top: -2rem
    }
}
@media screen and (min-width: 1200px) {
    .cybersec-training-moto-icon-size{
        width: 6rem;
        left: -4rem;
        top: 5rem
    }
}




@media screen and (max-width: 360px) {
    .cybersec-training-form-jarda {
        width: 100%;
        margin-top: -4rem;
        margin-left: 0rem;
        margin-bottom: 1rem;
    }   
}
@media screen and (min-width: 360px) {
    .cybersec-training-form-jarda {
        width: 70%;
        margin-top: -7rem;
        margin-left: 0rem;
        margin-bottom: 2rem;
    }
}
@media screen and (min-width: 576px) {
    .cybersec-training-form-jarda {
        width: 80%;
        margin-top: -7rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0rem;
    }
}
@media screen and (min-width: 768px) {
    .cybersec-training-form-jarda {
        width: 90%;
        margin-top: -7rem;
        margin-left: 0rem;
        margin-bottom: 2rem;
    }
}
@media screen and (min-width: 992px) {
    .cybersec-training-form-jarda {
        width: 100%;
        margin-top: -1rem;
        margin-left: 0rem;
        margin-bottom: 0;
    }
}
@media screen and (min-width: 1200px) {
    .cybersec-training-form-jarda {
        width: 110%;
        margin-top: -3rem;
        margin-left: -2rem;
        margin-bottom: 0;

    }
}
@media screen and (min-width: 1400px) {
    .cybersec-training-form-jarda {
        width: 110%;
        margin-top: -10rem;
        margin-left: -2rem;
        margin-bottom: 0;

    }
}