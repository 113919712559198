@media screen and (min-width: 768px) {
    .style-zoom-1 {
        transition: transform 0.3s;
    }
    .style-zoom-1:hover {
        transform: scale(1.025);
    }
}
@media screen and (min-width: 768px) {
    .style-zoom-2 {
        transition: transform 0.3s;
    }
    .style-zoom-2:hover {
        transform: scale(1.05);
    }
}
@media screen and (min-width: 768px) {
    .style-zoom-3 {
        transition: transform 0.3s;
    }
    .style-zoom-3:hover {
        transform: scale(1.075);
    }
}
@media screen and (min-width: 768px) {
    .style-zoom-4 {
        transition: transform 0.3s;
    }
    .style-zoom-4:hover {
        transform: scale(1.1);
    }
}
@media screen and (min-width: 768px) {
    .style-zoom-5 {
        transition: transform 0.3s;
    }
    .style-zoom-5:hover {
        transform: scale(1.2);
    }
}
