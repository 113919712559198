@media screen and (max-width: 360px) {
    /* This should have same height and min-height as home-hero-text */
    .home-hero-section {
        height: 100vh;
        background: var(--divelit-grey-90);
        min-height: 28rem;
    }
    .home-hero-bg-img {
        background: radial-gradient(circle, rgba(0,0,0,0.75) 0%, rgba(26,26,26,0.8) 40%, rgba(26,26,26,0.9) 100%), url("../../images/pages/home/divelit_d_vector.webp");
        background-position: center 50%;
        background-size: calc(max(20rem, 50vh));
        background-repeat: no-repeat;
        overflow: hidden;
    }
    /* This should have same height and min-height as home-hero-section */
    .home-hero-text {
        height: 100vh;
        min-height: 32rem;
    }
    .home-hero-text-alignment {
        padding-left: 1.5rem;
        align-self: center;
    }
}
@media  screen and (min-width: 360px) {
    .home-hero-section {
        height: 100vh;
        background: var(--divelit-grey-90);
        min-height: 30rem;
    }
    .home-hero-bg-img {
        background: radial-gradient(circle, rgba(0,0,0,0.75) 0%, rgba(26,26,26,0.8) 40%, rgba(26,26,26,0.9) 100%), url("../../images/pages/home/divelit_d_vector.webp");
        background-position: center 80%;
        background-size: calc(max(25rem, 70vh));
        background-repeat: no-repeat;
        overflow: hidden;
    }
    /* This should have same height and min-height as home-hero-section */
    .home-hero-text {
        height: 100vh;
        min-height: 35rem;
    }
    .home-hero-text-alignment {
        padding-left: 1.5rem;
        align-self: center;
    }
}
@media screen and (min-width: 576px) {
    .home-hero-section {
        height: 100vh;
        background: var(--divelit-grey-90);
        min-height: 25rem;
    }
    .home-hero-bg-img {
        background: radial-gradient(circle, rgba(0,0,0,0.75) 0%, rgba(26,26,26,0.8) 40%, rgba(26,26,26,0.9) 100%), url("../../images/pages/home/divelit_d_vector.webp");
        background-position: center 5%;
        background-size: calc(max(25rem, 80vh));
        background-repeat: no-repeat;
        overflow: hidden;
    }
    /* This should have same height and min-height as home-hero-section */
    .home-hero-text {
        height: 100vh;
        min-height: 30rem;
    }
    .home-hero-text-alignment {
        padding-left: 2rem;
        align-self: center;
    }
}
@media screen and (min-width: 768px) {
    .home-hero-section {
        height: 100vh;
        background: var(--divelit-grey-90);
        min-height: 30rem;
    }
    .home-hero-bg-img {
        background: radial-gradient(circle, rgba(0,0,0,0.75) 0%, rgba(26,26,26,0.8) 40%, rgba(26,26,26,0.9) 100%), url("../../images/pages/home/divelit_d_vector.webp");
        background-position: center 5%;
        background-size: calc(max(30rem, 80vh));
        background-repeat: no-repeat;
        overflow: hidden;
    }
    /* This should have same height and min-height as home-hero-section */
    .home-hero-text {
        height: 100vh;
        min-height: 35rem;
    }
    .home-hero-text-alignment {
        padding-left: 2.5rem;
        align-self: center;
    }
}
@media screen and (min-width: 992px) {
    .home-hero-section {
        height: 100vh;
        background: var(--divelit-grey-90);
        min-height: 45rem;
    }
    .home-hero-bg-img {
        background: url("../../images/pages/home/divelit_d_vector.webp");
        background-position: 120% 10%;
        background-size: calc(max(40rem, 70vh));
        background-repeat: no-repeat;
        overflow: hidden;
    }
    /* This should have same height and min-height as home-hero-section */
    .home-hero-text {
        height: 100vh;
        min-height: 45rem;
    }
    .home-hero-text-alignment {
        padding-left: 3rem;
        padding-right: 3rem;
        height: 100%;
        align-self: center;
        background: linear-gradient(90deg, rgba(26, 26, 26,  1) 0%, rgba(26, 26, 26,  0.7) 75%, rgba(26, 26, 26,  0.5) 85%, rgba(26, 26, 26,  0.2) 95%, rgba(26, 26, 26,  0.0) 100%);
    }
    .home-hero-text-alignment-content {
        margin-top: auto;
        margin-bottom: auto;
    }
}
@media screen and (min-width: 1200px) {
    .home-hero-section {
        height: 100vh;
        background: var(--divelit-grey-90);
        min-height: 45rem;
    }
    .home-hero-bg-img {
        background: url("../../images/pages/home/divelit_d_vector.webp");
        background-position: 100% 0%;
        background-size: calc(max(40rem, 80vh));
        background-repeat: no-repeat;
        overflow: hidden;
    }
    /* This should have same height and min-height as home-hero-section */
    .home-hero-text {
        height: 100vh;
        min-height: 45rem;
    }
    .home-hero-text-alignment {
        padding-left: 4rem;
        padding-right: 10rem;
        height: 100%;
        align-self: center;
        background: linear-gradient(90deg, rgba(26, 26, 26,  1) 0%, rgba(26, 26, 26,  0.7) 75%, rgba(26, 26, 26,  0.5) 85%, rgba(26, 26, 26,  0.2) 95%, rgba(26, 26, 26,  0.0) 100%);
    }
    .home-hero-text-alignment-content {
        margin-top: auto;
        margin-bottom: auto;
    }
}
@media screen and (min-width: 1400px) {
    .home-hero-section {
        height: 100vh;
        min-height: 45rem;
    }
    .home-hero-bg-img {
        background: url("../../images/pages/home/divelit_d_vector.webp");
        background-position: 90% 20%;
        background-size: calc(max(40rem, 100vh));
        background-repeat: no-repeat;
        overflow: hidden;
    }
    /* This should have same height and min-height as home-hero-section */
    .home-hero-text {
        height: 100vh;
        min-height: 50rem;
    }
    .home-hero-text-alignment {
        padding-left: 10rem;
        padding-right: 10rem;
        height: 100%;     
        align-self: center;
        background: linear-gradient(90deg, rgba(26, 26, 26,  1) 0%, rgba(26, 26, 26,  0.7) 75%, rgba(26, 26, 26,  0.5) 85%, rgba(26, 26, 26,  0.2) 95%, rgba(26, 26, 26,  0.0) 100%);
    }
    .home-hero-text-alignment-content {
        margin-top: auto;
        margin-bottom: auto;
    }
}
