/* BOOSTRAP CLASSES customization >>> */
.dropdown {
    text-align: center;
}
.dropdown-menu {
	background-color: var(--divelit-grey-80);
	min-width: 3rem !important;
    width: fit-content;
    padding: 0;
    border-radius: 0.5rem;
}
.dropdown-item {
	text-decoration: none;
	padding: 0;
    margin: 0;
	width: 4rem;
    height: 3rem;
    text-align: center;
}
.dropdown-item:hover {
	background-color: var(--divelit-grey-60) !important;
    transition: 0.3s;
    border-radius: 0.5rem;
    color: var(--divelit-lemon) !important;
}
@media screen and (max-width: 360px) {
    .dropdown-menu {
        margin-top: -1.0rem !important;
        text-align: center !important;
        position:  relative !important;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.5rem;
    }
}
@media  screen and (min-width: 360px) {
    .dropdown-menu {
        margin-top: -1.25rem !important;
        text-align: center !important;
        position:  relative !important;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.75rem;
    }
}
@media screen and (min-width: 576px) {
    .dropdown-menu {
        margin-top: -1.5rem !important;
        position:  relative !important;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.0rem;
    }
}
@media screen and (min-width: 992px) {
    .dropdown-menu {
        margin-top: 0.25rem !important;
        position:  absolute !important;
    }
    .dropdown-item-services {
        text-align: left;
    }
}
/* <<< BOOTSTRAP CLASSES customization */
