:root {
    /* OLD company color */
    /*--yellow: #ffcc00;*/

    /* WILL REMAIN */ 
    --black: black;
    --white: white;
    /* NEW company colors */
    /* #D7DB35 */
    --divelit-lemon: rgb(215, 219, 53);

    /* DIVELIT LEMON 15 points DARKER*/
    --divelit-lemon-dark-1: rgb(200, 204, 38);
    /* #AACA3E */
    --divelit-lime: rgb(169, 202, 62);
    /* #6EB746 */
    --divelit-grass: rgb(110, 183, 70);
    /* #00A15C */
    --divelit-cucumber: rgb(0, 161, 92);
    /* #5D4B9A */
    --divelit-plum: rgb(93, 75, 154);
    --divelit-plum-10: #EFEDF5;
    --divelit-plum-15: #E7E4F0;
    --divelit-plum-20: #DFDBEB;
    --divelit-plum-25: #D6D2E6;
    --divelit-plum-30: #CEC9E1;
    --divelit-plum-40: #BEB7D7;
    --divelit-plum-50: #AEA5CC;
    --divelit-plum-60: #9E93C2;
    --divelit-plum-70: #8E81B8;
    --divelit-plum-80: #7D6FAE;
    --divelit-plum-90: #6D5DA4;
    
    /* (BG color from Rency's Figma prototype) - 1 point darker than black-90*/
    --divelit-black: #191919;
    /* fira logo color - mandatory */
    --grey-fira: #5c5e63;
    --linet-red: #e10600;
    --color-2n-blue: #005598;
    --color-numazon-green: #00a651;

    /* divelit gray scale */
    --divelit-grey-98: #050505;
    --divelit-grey-97: #080808;
    --divelit-grey-95: #0D0D0D;
    --divelit-grey-90: #1A1A1A;    /* use as the page background PROBABLY */
    --divelit-grey-80: #333333;
    --divelit-grey-70: #4D4D4D;
    --divelit-grey-60: #666666;
    --divelit-grey-50: #808080;
    --divelit-grey-40: #999999;
    --divelit-grey-30: #B3B3B3;
    --divelit-grey-20: #cccccc;
    --divelit-grey-10: #E6E6E6;
    --divelit-grey-05: #F2F2F2;
    --divelit-grey-03: #F7F7F7;
    --divelit-grey-02: #FAFAFA;


    --divelit-rency-grey-90: #191919;

    --divelit-lemon-dark-90: #C1C530;
    --divelit-lemon-dark-80: #ACAF2A;

    --divelit-plum-dark-90: #54438B;
    --divelit-plum-dark-80: #4A3C7B;
}

.bg-linet-red {
    background-color: var(--linet-red);
}
.bg-veit-red {
    background-color: var(--veit-red);
}
.bg-divelit-lemon {
    background-color: var(--divelit-lemon);
}
.bg-divelit-lime {
    background-color: var(--divelit-lime);
}
.bg-divelit-grass {
    background-color: var(--divelit-grass);
}
.bg-divelit-cucumber {
    background-color: var(--divelit-cucumber);
}
.bg-divelit-plum {
    background-color: var(--divelit-plum);
}
.bg-divelit-plum-10 {
    background-color: var(--divelit-plum-10);
}
.bg-divelit-plum-15 {
    background-color: var(--divelit-plum-15);
}
.bg-divelit-plum-20 {
    background-color: var(--divelit-plum-20);
}
.bg-divelit-plum-25 {
    background-color: var(--divelit-plum-25);
}
.bg-divelit-plum-30 {
    background-color: var(--divelit-plum-30);
}
.bg-divelit-plum-40 {
    background-color: var(--divelit-plum-40);
}
.bg-divelit-plum-50 {
    background-color: var(--divelit-plum-50);
}
.bg-divelit-plum-60 {
    background-color: var(--divelit-plum-60);
}
.bg-divelit-plum-70 {
    background-color: var(--divelit-plum-70);
}
.bg-divelit-plum-80 {
    background-color: var(--divelit-plum-80);
}
.bg-divelit-plum-90 {
    background-color: var(--divelit-plum-90);
}
.bg-divelit-grey-99 {
    background-color: var(--divelit-grey-99);
}
.bg-divelit-grey-98 {
    background-color: var(--divelit-grey-98);
}
.bg-divelit-grey-97 {
    background-color: var(--divelit-grey-97);
}
.bg-divelit-grey-95 {
    background-color: var(--divelit-grey-95);
}
.bg-divelit-grey-90 {
    background-color: var(--divelit-grey-90);
}
.bg-divelit-grey-80 {
    background-color: var(--divelit-grey-80);
}
.bg-divelit-grey-70 {
    background-color: var(--divelit-grey-70);
}
.bg-divelit-grey-60 {
    background-color: var(--divelit-grey-60);
}
.bg-divelit-grey-50 {
    background-color: var(--divelit-grey-50);
}
.bg-divelit-grey-40 {
    background-color: var(--divelit-grey-40);
}
.bg-divelit-grey-30 {
    background-color: var(--divelit-grey-30);
}
.bg-divelit-grey-20 {
    background-color: var(--divelit-grey-20);
}
.bg-divelit-grey-10 {
    background-color: var(--divelit-grey-10);
}
.bg-divelit-grey-05 {
    background-color: var(--divelit-grey-05);
}
.bg-divelit-grey-03 {
    background-color: var(--divelit-grey-03);
}
.bg-divelit-grey-02 {
    background-color: var(--divelit-grey-02);
}
.bg-divelit-grey-01 {
    background-color: var(--divelit-grey-01);
}
.bg-divelit-rency-grey-90 {
    background-color: var(--divelit-rency-grey-90);
}


.b-top-divelit-lemon-12 {
    border-top: 12px solid var(--divelit-lemon);
}
.b-top-divelit-lime-12 {
    border-top: 12px solid var(--divelit-lime);
}
.b-top-divelit-grass-12 {
    border-top: 12px solid var(--divelit-grass);
}
.b-top-divelit-cucumber-12 {
    border-top: 12px solid var(--divelit-cucumber);
}
.b-top-divelit-plum-12 {
    border-top: 12px solid var(--divelit-plum);
}


.hover-decoration-none {
    text-decoration: none;
}
.hover-linet-red:hover {
    transition: 0.3s;
    color: var(--linet-red);
}
.hover-veit-red:hover {
    transition: 0.3s;
    color: var(--veit-red);
}
.hover-divelit-lemon:hover {
    transition: 0.3s;
    color: var(--divelit-lemon);
}
.hover-divelit-lime:hover {
    transition: 0.3s;
    color: var(--divelit-lime);
}
.hover-divelit-grass:hover {
    transition: 0.3s;
    color: var(--divelit-grass);
}
.hover-divelit-cucumber:hover {
    transition: 0.3s;
    color: var(--divelit-cucumber);
}
.hover-divelit-plum:hover {
    transition: 0.3s;
    color: var(--divelit-plum);
}
.hover-divelit-grey-98:hover {
    transition: 0.3s;
    color: var(--divelit-grey-98);
}
.hover-divelit-grey-97:hover {
    transition: 0.3s;
    color: var(--divelit-grey-97);
}
.hover-divelit-grey-95:hover {
    transition: 0.3s;
    color: var(--divelit-grey-95);
}
.hover-divelit-grey-90:hover {
    transition: 0.3s;
    color: var(--divelit-grey-90);
}
.hover-divelit-grey-80:hover {
    transition: 0.3s;
    color: var(--divelit-grey-80);
}
.hover-divelit-grey-70:hover {
    transition: 0.3s;
    color: var(--divelit-grey-70);
}
.hover-divelit-grey-60:hover {
    transition: 0.3s;
    color: var(--divelit-grey-60);
}
.hover-divelit-grey-50:hover {
    transition: 0.3s;
    color: var(--divelit-grey-50);
}
.hover-divelit-grey-40:hover {
    transition: 0.3s;
    color: var(--divelit-grey-40);
}
.hover-divelit-grey-30:hover {
    transition: 0.3s;

    color: var(--divelit-grey-30);
}
.hover-divelit-grey-20:hover {
    transition: 0.3s;
    color: var(--divelit-grey-20);
}
.hover-divelit-grey-10:hover {
    transition: 0.3s;
    color: var(--divelit-grey-10);
}
.hover-divelit-grey-05:hover {
    transition: 0.3s;
    color: var(--divelit-grey-05);
}
.hover-divelit-grey-03:hover {
    transition: 0.3s;

    color: var(--divelit-grey-03);
}
.hover-divelit-grey-02:hover {
    transition: 0.3s;
    color: var(--divelit-grey-02);
}


.hover-divelit-rency-grey-90:hover {
    transition: 0.3s;
    color: var(--divelit-rency-grey-90);
}
