@media screen and (max-width: 768px) {
    .sepparator-vert {
        border:none;
        height: 3rem;
    }
    .sepparator-vert-grey-98 {
        border:none;
    }
    .sepparator-vert-grey-97 {
        border:none;
    }
    .sepparator-vert-grey-95 {
        border:none;
    }
    .sepparator-vert-grey-90 {
        border:none;
    }
    .sepparator-vert-grey-80 {
        border:none;
    }
    .sepparator-vert-grey-70 {
        border:none;
    }
    .sepparator-vert-grey-60 {
        border:none;
    }
    .sepparator-vert-grey-50 {
        border:none;
    }
    .sepparator-vert-grey-40 {
        border:none;
    }
    .sepparator-vert-grey-30 {
        border:none;
    }
    .sepparator-vert-grey-20 {
        border:none;
    }
    .sepparator-vert-grey-20 {
        border:none;
    }
    .sepparator-vert-grey-10 {
        border:none;
    }
    .sepparator-vert-grey-05 {
        border:none;
    }
    .sepparator-vert-grey-03 {
        border:none;
    }
    .sepparator-vert-grey-02 {
        border:none;
    }
    
}
@media screen and (min-width: 768px) {
    .sepparator-vert {
        width: 1px;
        height: 5rem; 
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .sepparator-vert-grey-98 {
        border-left: 1px solid var(--divelit-grey-98);
    }
    .sepparator-vert-grey-97 {
        border-left: 1px solid var(--divelit-grey-97);
    }
    .sepparator-vert-grey-95 {
        border-left: 1px solid var(--divelit-grey-95);
    }
    .sepparator-vert-grey-90 {
        border-left: 1px solid var(--divelit-grey-90);
    }
    .sepparator-vert-grey-80 {
        border-left: 1px solid var(--divelit-grey-80);
    }
    .sepparator-vert-grey-70 {
        border-left: 1px solid var(--divelit-grey-70);
    }
    .sepparator-vert-grey-60 {
        border-left: 1px solid var(--divelit-grey-60);
    }
    .sepparator-vert-grey-50 {
        border-left: 1px solid var(--divelit-grey-50);
    }
    .sepparator-vert-grey-40 {
        border-left: 1px solid var(--divelit-grey-40);
    }
    .sepparator-vert-grey-30 {
        border-left: 1px solid var(--divelit-grey-30);
    }
    .sepparator-vert-grey-20 {
        border-left: 1px solid var(--divelit-grey-20);
    }
    .sepparator-vert-grey-20 {
        border-left: 1px solid var(--divelit-grey-20);
    }
    .sepparator-vert-grey-10 {
        border-left: 1px solid var(--divelit-grey-10);
    }
    .sepparator-vert-grey-05 {
        border-left: 1px solid var(--divelit-grey-05);
    }
    .sepparator-vert-grey-03 {
        border-left: 1px solid var(--divelit-grey-03);
    }
    .sepparator-vert-grey-02 {
        border-left: 1px solid var(--divelit-grey-02);
    }
}
@media screen and (min-width: 992px) {
    .sepparator-vert {
        width: 1px;
        height: 5rem; 
        padding: 0;
        margin-left: 2rem;
        margin-right: 2rem;
    }
}
@media screen and (min-width: 1200px) {
    .sepparator-vert {
        width: 1px;
        height: 5rem; 
        padding: 0;
        margin-left: 4rem;
        margin-right: 4rem;
    }
}
@media screen and (min-width: 1400px) {
    .sepparator-vert {
        width: 1px;
        height: 5rem; 
        padding: 0;
        margin-left: 5rem;
        margin-right: 5rem;
    }
}



/* TODO DELETE */
.sepparator-horiz-divelit-grey-10-2px {
    border-top: 2px solid var(--divelit-grey-10);
}





/* black & white */
.sepparator-horiz-black {
    border-top: 1px solid var(--black);
    margin: 0;
}
.sepparator-horiz-white {
    border-top: 1px solid var(--white);
    margin: 0;
}
.sepparator-horiz-black-2px {
    border-top: 2px solid var(--black);
    margin: 0;
}
.sepparator-horiz-white-2px {
    border-top: 2px solid var(--white);
    margin: 0;
}



/* divelit colors */
.sepparator-horiz-divelit-lemon {
    border-top: 1px solid var(--divelit-lemon);
    margin: 0;
}.sepparator-horiz-divelit-lime {
    border-top: 1px solid var(--divelit-lime);
    margin: 0;
}.sepparator-horiz-divelit-grass {
    border-top: 1px solid var(--divelit-grass);
    margin: 0;
}.sepparator-horiz-divelit-cucumber {
    border-top: 1px solid var(--divelit-cucumber);
    margin: 0;
}.sepparator-horiz-divelit-plum {
    border-top: 1px solid var(--divelit-plum);
    margin: 0;
}



/* divelit gray scale */
.sepparator-horiz-divelit-grey-98 {
    border-top: 1px solid var(--divelit-grey-98);
    margin: 0;
}
.sepparator-horiz-divelit-grey-97 {
    border-top: 1px solid var(--divelit-grey-97);
    margin: 0;
}
.sepparator-horiz-divelit-grey-95 {
    border-top: 1px solid var(--divelit-grey-95);
    margin: 0;
}
.sepparator-horiz-divelit-grey-90 {
    border-top: 1px solid var(--divelit-grey-90);
    margin: 0;
}
.sepparator-horiz-divelit-grey-80 {
    border-top: 1px solid var(--divelit-grey-80);
    margin: 0;
}
.sepparator-horiz-divelit-grey-70 {
    border-top: 1px solid var(--divelit-grey-70);
    margin: 0;
}
.sepparator-horiz-divelit-grey-60 {
    border-top: 1px solid var(--divelit-grey-60);
    margin: 0;
}
.sepparator-horiz-divelit-grey-50 {
    border-top: 1px solid var(--divelit-grey-50);
    margin: 0;
}
.sepparator-horiz-divelit-grey-40 {
    border-top: 1px solid var(--divelit-grey-40);
    margin: 0;
}
.sepparator-horiz-divelit-grey-30 {
    border-top: 1px solid var(--divelit-grey-30);
    margin: 0;
}
.sepparator-horiz-divelit-grey-20 {
    border-top: 1px solid var(--divelit-grey-20);
    margin: 0;
}
.sepparator-horiz-divelit-grey-10 {
    border-top: 1px solid var(--divelit-grey-10);
    margin: 0;
}
.sepparator-horiz-divelit-grey-05 {
    border-top: 1px solid var(--divelit-grey-05);
    margin: 0;
}
.sepparator-horiz-divelit-grey-03 {
    border-top: 1px solid var(--divelit-grey-03);
    margin: 0;
}
.sepparator-horiz-divelit-grey-02 {
    border-top: 1px solid var(--divelit-grey-02);
    margin: 0;
}



/* MOBILE sepparator widths */
.sepparator-w-100 {
    width: 100%;
}
.sepparator-w-95 {
    width: 95%;
}
.sepparator-w-90 {
    width: 90%;
}
.sepparator-w-85 {
    width: 85%;
}
.sepparator-w-80 {
    width: 80%;
}
.sepparator-w-75 {
    width: 75%;
}
.sepparator-w-70 {
    width: 70%;
}
.sepparator-w-65 {
    width: 65%;
}
.sepparator-w-60 {
    width: 60%;
}
.sepparator-w-55 {
    width: 55%;
}
.sepparator-w-50 {
    width: 50%;
}
.sepparator-w-45 {
    width: 45%;
}
.sepparator-w-40 {
    width: 40%;
}
.sepparator-w-35 {
    width: 35%;
}
.sepparator-w-30 {
    width: 30%;
}
.sepparator-w-25 {
    width: 25%;
}
.sepparator-w-20 {
    width: 20%;
}
.sepparator-w-15 {
    width: 15%;
}
.sepparator-w-10 {
    width: 10%;
}
.sepparator-w-5 {
    width: 5%;
}



/* DESKTOP sepparator widths */
@media screen and (min-width: 768px) {
    .sepparator-md-w-100 {
        width: 100%;
    }
    .sepparator-md-w-95 {
        width: 95%;
    }
    .sepparator-md-w-90 {
        width: 90%;
    }
    .sepparator-md-w-85 {
        width: 85%;
    }
    .sepparator-md-w-80 {
        width: 80%;
    }
    .sepparator-md-w-75 {
        width: 75%;
    }
    .sepparator-md-w-70 {
        width: 70%;
    }
    .sepparator-md-w-65 {
        width: 65%;
    }
    .sepparator-md-w-60 {
        width: 60%;
    }
    .sepparator-md-w-55 {
        width: 55%;
    }
    .sepparator-md-w-50 {
        width: 50%;
    }
    .sepparator-md-w-45 {
        width: 45%;
    }
    .sepparator-md-w-40 {
        width: 40%;
    }
    .sepparator-md-w-35 {
        width: 35%;
    }
    .sepparator-md-w-30 {
        width: 30%;
    }
    .sepparator-md-w-25 {
        width: 25%;
    }
    .sepparator-md-w-20 {
        width: 20%;
    }
    .sepparator-md-w-15 {
        width: 15%;
    }
    .sepparator-md-w-10 {
        width: 10%;
    }
    .sepparator-md-w-5 {
        width: 5%;
    }
}
