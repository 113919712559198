
@media screen and (max-width: 576px) {

}
@media screen and (min-width: 576px) {

}
@media screen and (max-width: 768px) {
    .career-join-us-bg-img {
        background-color: var(--divelit-grey-90);
        max-width: 100%;
        height: auto;
    
        max-width: 100%;
        display: block;
        position: relative;
        z-index: 1;
        border-bottom: 1px solid var(--divelit-grey-80);
    }
    .career-join-us-bg-img::after {
        content: "";
        filter: brightness(30%);

        background: url("../../background_images/career_vector_v2.svg");
        background-size: auto;
        background-position: 10rem 0rem;
        background-repeat: no-repeat, no-repeat;

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        
    }
}
@media screen and (min-width: 768px) {
    .career-join-us-bg-img {
        background: url("../../background_images/career_vector_v2.svg");
        background-size: auto;
        background-position: 35rem 3.25rem;
        background-repeat: no-repeat, no-repeat;
    }
}
@media screen and (min-width: 992px) {
    .career-join-us-bg-img {
        background: url("../../background_images/career_vector_v2.svg");
        background-size: auto;
        background-position: 35rem 4rem;
        background-repeat: no-repeat, no-repeat;
    }
}
@media screen and (min-width: 1200px) {
    .career-join-us-bg-img {
        background: url("../../background_images/career_vector_v2.svg");
        background-size: auto;
        background-position: right 4.5rem;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 1400px) {
    .career-join-us-bg-img {
        background: url("../../background_images/career_vector_v2.svg");
        background-size: auto;
        background-position: right 5rem;
        background-repeat: no-repeat;
    }
}

.career-join-us-section {
    background-color: var(--divelit-grey-90);
}
