.home-start-section {
    background-color: var(--divelit-grey-90);
    max-width: 100%;
    height: auto;

    max-width: 100%;
    display: block;
    position: relative;
    z-index: 1;
}
@media screen and (max-width: 768px) {
    .home-start-section::after {
        content: "";
        background-image: url('../../images/pages/home/divelit_contact_us_vector.svg');
        /*filter:grayscale(100%);*/
        filter: brightness(60%);
        background-size: cover;
        background-position: left;
       /* background-position: -700% 90%, 800% 10%;*/
        background-repeat: no-repeat;
       
        max-width: 100%;
        height: auto;
        color: var(--black);

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}
@media screen and (min-width: 768px) {
    .home-start-section::after {
        content: "";
        background-image: url('../../images/pages/home/divelit_contact_us_vector.svg'), url('../../images/pages/home/divelit_contact_us_vector.svg');
        background-size: 120%;
        background-position: 475%, -375%;
        background-repeat: no-repeat, no-repeat;
       
        max-width: 100%;
        height: auto;
        color: var(--black);

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}
@media screen and (min-width: 992px) {
    .home-start-section::after {
        content: "";

        background-image: url('../../images/pages/home/divelit_contact_us_vector.svg'), url('../../images/pages/home/divelit_contact_us_vector.svg');
        background-size: 90%;
        background-position: -700%, 800%;
        background-repeat: no-repeat, no-repeat;
       
        max-width: 100%;
        height: auto;
        color: var(--black);

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}
@media screen and (min-width: 1200px) {
    .home-start-section::after {
        content: "";

        background-image: url('../../images/pages/home/divelit_contact_us_vector.svg'), url('../../images/pages/home/divelit_contact_us_vector.svg');
        background-size: 78%;
        background-position: -250%, 330%;
        background-repeat: no-repeat, no-repeat;
       
        max-width: 100%;
        height: auto;
        color: var(--black);

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}
@media screen and (min-width: 1400px) {
    .home-start-section::after {
        content: "";

        background-image: url('../../images/pages/home/divelit_contact_us_vector.svg'), url('../../images/pages/home/divelit_contact_us_vector.svg');
        background-size: 63%;
        background-position: -100%, 200%;
        background-repeat: no-repeat, no-repeat;
       
        max-width: 100%;
        height: auto;
        color: var(--black);

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}
