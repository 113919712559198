@media screen and (max-width: 768px) {
    .home-partners-comap-img {
        height: 2.5rem;
    }
    .home-partners-rieter-img {
        height: 2.0rem;
    }
    .home-partners-jablotron-img {
        height: 3rem;
    }
    .home-partners-2n-img {
        height: 2.5rem;
    }
    .home-partners-linet-img {
        height: 1.6rem;
    }
    .home-partners-container-padding {
        padding-top: 7rem;
        padding-bottom: 5rem;
    }
    .home-partners-header-margin {
        margin-top: 3rem;
    }
}
@media screen and (min-width: 768px) {
    .home-partners-comap-img {
        height: 2.5rem;
    }
    .home-partners-rieter-img {
        height: 2.0rem;
    }
    .home-partners-jablotron-img {
        height: 3rem;
    }
    .home-partners-2n-img {
        height: 2.5rem;
    }
    .home-partners-linet-img {
        height: 1.6rem;
    }
    .home-partners-container-padding {
        padding-top: 12rem;
        padding-bottom: 5rem;
    }
    .home-partners-header-margin {
        margin-top: 3rem;
    }
}
@media screen and (min-width: 992px) {
    .home-partners-comap-img {
        height: 2.75rem;
    }
    .home-partners-rieter-img {
        height: 2.25rem;
    }
    .home-partners-jablotron-img {
        height: 3rem;
    }
    .home-partners-2n-img {
        height: 2.75rem;
    }
    .home-partners-linet-img {
        height: 1.8rem;
    }
    .home-partners-margin {
        margin-top: 12rem;
        margin-bottom: 5rem;
    }
    .home-partners-header-margin {
        margin-top: 5rem;
    }
    .home-partners-container-padding {
        padding-top: 14rem;
        padding-bottom: 5rem;
    }
    .home-partners-header-margin {
        margin-top: 4rem;
    }
}
@media screen and (min-width: 1200px) {
    .home-partners-comap-img {
        height: 3rem;
    }
    .home-partners-rieter-img {
        height: 2.375rem;
    }
    .home-partners-jablotron-img {
        height: 3rem;
    }
    .home-partners-2n-img {
        height: 3rem;
    }
    .home-partners-linet-img {
        height: 2.0rem;
    }
    .home-partners-container-padding {
        padding-top: 10rem;
        padding-bottom: 5rem;
    }
    .home-partners-header-margin {
        margin-top: 5rem;
    }
}
@media screen and (min-width: 1400px) {
    .home-partners-comap-img {
        height: 3rem;
    }    
    .home-partners-rieter-img {
        height: 2.375rem;
    }
    .home-partners-jablotron-img {
        height: 3rem;
    }
    .home-partners-2n-img {
        height: 3rem;
    }
    .home-partners-linet-img {
        height: 2.0rem;
    }
    .home-partners-container-padding {
        padding-top: 12rem;
        padding-bottom: 7rem;
    }
    .home-partners-header-margin {
        margin-top: 5rem;
    }
}

.home-partners-img {
    /*filter: brightness(0) invert(0);
    transition: 0.3s;*/
    
    animation-name: reverseanimation;
    animation-duration: 0.3s;
    filter: brightness(0) invert(0);
}
.home-partners-img:hover {
    /*filter: none;
    transition: 0.3s;*/
    animation-name: myanimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards; 
}
@keyframes myanimation { 
    from { 
        filter: brightness(0) invert(0);
    } 
    to { 
        filter: none;
    } 
} 
  
@keyframes reverseanimation { 
    from { 
        filter: none;
    } 
    to { 
        filter: brightness(0) invert(0);
    } 
} 