.paragraph-medium {
    font-family: poppins-regular;
}

.paragraph-small {
    font-family: poppins-light;
}

.paragraph-extra-small {
    font-family: poppins-light;
}



@media screen and (max-width: 768px) {
    .paragraph-medium {
        font-size: 1.125rem;
    }
    .paragraph-small {
        font-size: 1.000rem;
    }
    .paragraph-extra-small {
        font-size: 0.750rem;        
    }
}
@media screen and (min-width: 768px) {
    .paragraph-medium {
        font-size: 1.250rem;
        line-height: 1.6;
    }
    .paragraph-small {
        font-size: 1.125rem;
        line-height: 1.6;
    }
    .paragraph-extra-small {
        font-size: 0.875rem;      
        line-height: 1.6;
    }
}
