/* BUTTON STYLE SOURCE */

/* HTML */
/* 
<div class="container">
  <h1>Pure Css Button Hover effect</h1>
  
  <a href="#" class="button">Hover me</a>
  
<!--  optional  -->
  <span>Made by <a href="http://alticreation.com/en">alticreation.com</a></span>
</div>
*/

/* SCSS */
/*
$color: #0cf;

.button {
  display: inline-block;
  padding: .75rem 1.25rem;
  border-radius: 10rem;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color;
    border-radius: 10rem;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken($color, 15%);
    transition: all .3s;
    border-radius: 10rem;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }
}
*/
.button-arrow {
    margin-left: 1.5rem;
    content: url("../../icons/rency/arrow_black.svg");
}

.button-arrow-down {
    transform: rotate(90deg);
    margin-left: 1.5rem;
    content: url("../../icons/rency/arrow_white.svg");
}

.button-arrow-down:hover {
    transform: rotate(90deg);
    margin-left: 1.5rem;
    content: url("../../icons/rency/arrow_black.svg");
}

/* Mobile */
@media screen and (max-width: 768px) {
    .button {
        display: inline-block;
        border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
        border: none;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        width: fit-content;
        height: 3.0rem;
        text-decoration: none;

        padding-right: 1.5rem;
        padding-left: 1.25rem;
        
        color: var(--black);
        line-height: 3.0rem;
        text-decoration: none;
        font-family: poppins-medium;
    }
    .button:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--divelit-lemon);
        border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
        z-index: -2;
    }
    .button:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: var(--divelit-lemon-dark-1);
        transition: all 0.3s;
        border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
        z-index: -1;
    }
    .button:hover {
        /*color: var(--white);*/
        color: var(--black);
        cursor: pointer;
    }
    .button:hover:before {
        width: 100%;
    }
}
/* Desktop */
@media screen and (min-width: 768px) {
    .button {
        display: inline-block;
        border-radius: 0.3rem 2rem 2rem 0.3rem;
        border: none;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        width: fit-content;
        height: 4rem;

        padding-right: 2.0rem;
        padding-left: 1.25rem;
        
        color: var(--black);
        line-height: 4rem;
        text-decoration: none;
        font-family: poppins-medium;
    }
    .button:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--divelit-lemon);
        border-radius: 0.3rem 2rem 2rem 0.3rem;
        z-index: -2;
    }
    .button:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: var(--divelit-lemon-dark-1);
        transition: all 0.3s;
        border-radius: 0.3rem 2rem 2rem 0.3rem;
        z-index: -1;
    }
    .button:hover {
        /*color: var(--white);*/
        color: var(--black);
        cursor: pointer;
    }
    .button:hover:before {
        width: 100%;
    }
}



/* Mobile */
@media screen and (max-width: 768px) {
  .button-upload {
      display: inline-block;
      border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
      border: none;
      transition: all 0.3s;
      position: relative;
      overflow: hidden;
      z-index: 1;
      width: 100%;
      height: 3rem;
      min-height: fit-content;
      text-decoration: none;

      padding-right: 1.5rem;
      padding-left: 1.25rem;
      
      color: var(--white);
      line-height: 3rem;
      text-decoration: none;
      font-family: poppins-medium;
  }
  .button-upload:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--black);
      border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
      z-index: -2;
  }
  .button-upload:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: var(--divelit-grey-50);
      transition: all 0.3s;
      border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
      z-index: -1;
  }
  .button-upload:hover {
      /*color: var(--white);*/
      color: var(--black);
      cursor: pointer;
  }
  .button-upload:hover:before {
      width: 100%;
  }
}
/* Desktop */
@media screen and (min-width: 768px) {
  .button-upload {
      display: inline-block;
      border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
      border: none;
      transition: all 0.3s;
      position: relative;
      overflow: hidden;
      z-index: 1;
      width: 100%;
      height: 4rem;

      padding-right: 2.0rem;
      padding-left: 1.25rem;
      
      color: var(--white);
      line-height: 4rem;
      text-decoration: none;
      font-family: poppins-medium;
  }
  .button-upload:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--divelit-black);
      border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
      z-index: -2;
  }
  .button-upload:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: var(--divelit-grey-50);
      transition: all 0.3s;
      border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
      z-index: -1;
  }
  .button-upload:hover {
      /*color: var(--white);*/
      color: var(--black);
      cursor: pointer;
  }
  .button-upload:hover:before {
      width: 100%;
  }
}




/* Mobile */
@media screen and (max-width: 768px) {
    .button-w-border {
        display: inline-block;
        border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
        border: none;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        width: fit-content;
        height: 3.0rem;
        text-decoration: none;

        padding-right: 1.5rem;
        padding-left: 1.25rem;
        
        color: var(--black);
        line-height: 3.0rem;
        text-decoration: none;
        font-family: poppins-medium;
    }
    .button-w-border:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background-color: var(--divelit-lemon);*/
        border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
        border: 1px solid var(--black);
        z-index: -2;
    }
    .button-w-border:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.10);
        transition: all 0.3s;
        border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
        z-index: -1;
    }
    .button-w-border:hover {
        /*color: var(--white);*/
        color: var(--black);
        cursor: pointer;
    }
    .button-w-border:hover:before {
        width: 100%;
    }
}
/* Desktop */
@media screen and (min-width: 768px) {
    .button-w-border {
        display: inline-block;
        border-radius: 0.3rem 2rem 2rem 0.3rem;
        border: none;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        width: fit-content;
        height: 4rem;

        padding-right: 2.0rem;
        padding-left: 1.25rem;
        
        color: var(--black);
        line-height: 4rem;
        text-decoration: none;
        font-family: poppins-medium;
    }
    .button-w-border:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background-color: var(--divelit-lemon);*/
        border-radius: 0.3rem 2rem 2rem 0.3rem;
        border: 1px solid var(--black);
        z-index: -2;
    }
    .button-w-border:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.10);
        transition: all 0.3s;
        border-radius: 0.3rem 2rem 2rem 0.3rem;
        z-index: -1;
    }
    .button-w-border:hover {
        /*color: var(--white);*/
        color: var(--black);
        cursor: pointer;
    }
    .button-w-border:hover:before {
        width: 100%;
    }
}



/* Mobile */
@media screen and (max-width: 768px) {
    .button-wo-border {
        display: inline-block;
        border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
        border: none;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        width: fit-content;
        height: 3.0rem;
        text-decoration: none;

        padding-right: 1.5rem;
        padding-left: 1.25rem;
        
        color: var(--black);
        line-height: 3.0rem;
        text-decoration: none;
        font-family: poppins-medium;
    }
    .button-wo-border:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background-color: var(--divelit-lemon);*/
        border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
        border: none;
        z-index: -2;
    }
    .button-wo-border:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.10);
        transition: all 0.3s;
        border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
        z-index: -1;
    }
    .button-wo-border:hover {
        /*color: var(--white);*/
        color: var(--black);
        cursor: pointer;
    }
    .button-wo-border:hover:before {
        width: 100%;
    }
}
/* Desktop */
@media screen and (min-width: 768px) {
    .button-wo-border {
        display: inline-block;
        border-radius: 0.3rem 2rem 2rem 0.3rem;
        border: none;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        width: fit-content;
        height: 4rem;

        padding-right: 2.0rem;
        padding-left: 1.25rem;
        
        color: var(--black);
        line-height: 4rem;
        text-decoration: none;
        font-family: poppins-medium;
    }
    .button-wo-border:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background-color: var(--divelit-lemon);*/
        border-radius: 0.3rem 2rem 2rem 0.3rem;
        border: none;
        z-index: -2;
    }
    .button-wo-border:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.10);
        transition: all 0.3s;
        border-radius: 0.3rem 2rem 2rem 0.3rem;
        z-index: -1;
    }
    .button-wo-border:hover {
        /*color: var(--white);*/
        color: var(--black);
        cursor: pointer;
    }
    .button-wo-border:hover:before {
        width: 100%;
    }
}
