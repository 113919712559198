
/** ############################################################################################# */
/** USED ON INTERNAL SYSTEM - do not change class name (would require updating EDITOR code on IS) */
/** ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ */
:root {
    /* FONTS */
    --roboto-flex-font-variation-settings: "XTRA" 323, "wdth" 95, "wght" 570, "YOPQ" 100;
}
/** ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ */
/** END of USED */
/** ############################################################################################# */



/*FONTS*/
@font-face {
    font-family: 'dfont';
    src: url('../fonts/dfont-0.7.woff2') format('woff2');
}

@font-face {
    font-family: 'poppins-light';
    src: url('../fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'poppins-regular';
    src: url('../fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'poppins-medium';
    src: url('../fonts/Poppins-Medium.ttf') format('truetype');
}


/** ############################################################################################# */
/** USED ON INTERNAL SYSTEM - do not change class name (would require updating EDITOR code on IS) */
/** ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ */
@font-face {
    font-family: 'noto';
    src: url('../fonts/notosans-condensedextralight.ttf') format('truetype');
}
/*font-variation-settings: "wght" 540, "wdth" 50, "GRAD" 75, "YOPQ" 100, "XTRA" 400;*/
@font-face {
    /* There CAN NOT be used CSS variable - it won't work in Firefox - there have to be written values*/

    /* So for Google Chrome is required to have font-variation-settings on every place where this font is used through CSS variable */
    /* - works also for FF, but FF works with whats below and DOES NOT require to have those setting enywhere else*/
    font-variation-settings: "XTRA" 323, "wdth" 95, "wght" 570, "YOPQ" 100;
    font-family: 'roboto-flex';
    src: url('../fonts/RobotoFlex-VariableFont_GRAD,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC,opsz,slnt,wdth,wght.ttf') format('truetype'); 
}
/** ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ */
/** END of USED */
/** ############################################################################################# */


.font-white {
    color: var(--white);
}

.font-black {
    color: var(--black);
}

.font-divelit-grey-01 {
    color: var(--divelit-grey-01);
}
.font-divelit-grey-02 {
    color: var(--divelit-grey-02);
}
.font-divelit-grey-05 {
    color: var(--divelit-grey-05);
}
.font-divelit-grey-10 {
    color: var(--divelit-grey-10);
}
.font-divelit-grey-20 {
    color: var(--divelit-grey-20);
}
.font-divelit-grey-30 {
    color: var(--divelit-grey-30);
}
.font-divelit-grey-40 {
    color: var(--divelit-grey-40);
}
.font-divelit-grey-50 {
    color: var(--divelit-grey-50);
}
.font-divelit-grey-60 {
    color: var(--divelit-grey-60);
}
.font-divelit-grey-70 {
    color: var(--divelit-grey-70);
}
.font-divelit-grey-80 {
    color: var(--divelit-grey-80);
}
.font-divelit-grey-90 {
    color: var(--divelit-grey-90);
}
.font-divelit-grey-95 {
    color: var(--divelit-grey-95);
}
.font-divelit-grey-97 {
    color: var(--divelit-grey-97);
}
.font-divelit-grey-98 {
    color: var(--divelit-grey-98);
}


.font-divelit-lemon {
    color: var(--divelit-lemon);
}

.font-divelit-lime {
    color: var(--divelit-lime);
}

.font-divelit-grass {
    color: var(--divelit-grass);
}

.font-divelit-cucumber {
    color: var(--divelit-cucumber);
}

.font-divelit-plum {
    color: var(--divelit-plum);
}

.font-divelit-plum-70 {
    color: var(--divelit-plum-70);
}

.font-linet-red {
    color: var(--linet-red);
}
.font-veit-red {
    color: var(--veit-red);
}
