.fade-in-250 {
    animation: animationFrames2 ease 0.25s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards; /*when the spec is finished*/
    -webkit-animation: animationFrames2 ease 0.25s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: animationFrames2 ease 0.25s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames2 ease 0.25s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames2 ease 0.25s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}

.fade-in-500 {
    animation: animationFrames2 ease 0.50s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards; /*when the spec is finished*/
    -webkit-animation: animationFrames2 ease 0.50s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: animationFrames2 ease 0.50s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames2 ease 0.50s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames2 ease 0.50s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}

.fade-in-750 {
    animation: animationFrames2 ease 0.75s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards; /*when the spec is finished*/
    -webkit-animation: animationFrames2 ease 0.75s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: animationFrames2 ease 0.75s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames2 ease 0.75s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames2 ease 0.75s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}

.fade-in-1000 {
    animation: animationFrames2 ease 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards; /*when the spec is finished*/
    -webkit-animation: animationFrames2 ease 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: animationFrames2 ease 1s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames2 ease 1s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames2 ease 1s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}

.fade-in-1250 {
    animation: animationFrames2 ease 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards; /*when the spec is finished*/
    -webkit-animation: animationFrames2 ease 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: animationFrames2 ease 1s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames2 ease 1s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames2 ease 1s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}

.fade-in-1500 {
    animation: animationFrames2 ease 1.5s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards; /*when the spec is finished*/
    -webkit-animation: animationFrames2 ease 1.5s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: animationFrames2 ease 1.5s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames2 ease 1.5s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames2 ease 1.5s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}



/** 
  * classes marking elements on which SCROLL ANIMATION will be applied
  */
.scroll-anim-250,
.scroll-anim-500,
.scroll-anim-750,
.scroll-anim-1000,
.scroll-anim-1250,
.scroll-anim-1500,
.scroll-anim-1750,
.scroll-anim-2000,
.scroll-anim-2250,
.scroll-anim-2500,
.scroll-anim-2750,
.scroll-anim-3000 {
    /*visibility: hidden;*/
}


/** 
  * classes HIDING elements before SCROLL ANIMATION will be applied onto them
  */
.scroll-anim-250-hidden,
.scroll-anim-500-hidden,
.scroll-anim-750-hidden,
.scroll-anim-1000-hidden,
.scroll-anim-1250-hidden,
.scroll-anim-1500-hidden,
.scroll-anim-1750-hidden,
.scroll-anim-2000-hidden,
.scroll-anim-2250-hidden,
.scroll-anim-2500-hidden,
.scroll-anim-2750-hidden,
.scroll-anim-3000-hidden
{
    visibility: hidden;
}


/** classes used for SCROLL launched ANIMATIONS*/
.fade-in-250-down-to-top {
    animation: animationFrames ease 0.25s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards;
    -webkit-animation: animationFrames ease 0.25s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; 
    -moz-animation: animationFrames ease 0.25s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; 
    -o-animation: animationFrames ease 0.25s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; 
    -ms-animation: animationFrames ease 0.25s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; 
}
.fade-in-500-down-to-top {
    animation: animationFrames ease 0.50s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards;
    -webkit-animation: animationFrames ease 0.50s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; 
    -moz-animation: animationFrames ease 0.50s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; 
    -o-animation: animationFrames ease 0.50s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; 
    -ms-animation: animationFrames ease 0.50s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; 
}
.fade-in-750-down-to-top {
    animation: animationFrames ease 0.75s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards;
    -webkit-animation: animationFrames ease 0.75s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; 
    -moz-animation: animationFrames ease 0.75s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; 
    -o-animation: animationFrames ease 0.75s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; 
    -ms-animation: animationFrames ease 0.75s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; 
}
.fade-in-1000-down-to-top {
    animation: animationFrames ease 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards;
    -webkit-animation: animationFrames ease 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; 
    -moz-animation: animationFrames ease 1s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; 
    -o-animation: animationFrames ease 1s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; 
    -ms-animation: animationFrames ease 1s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; 
}
.fade-in-1250-down-to-top {
    animation: animationFrames ease 1.25s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards;
    -webkit-animation: animationFrames ease 1.25s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; 
    -moz-animation: animationFrames ease 1.25s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; 
    -o-animation: animationFrames ease 1.25s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; 
    -ms-animation: animationFrames ease 1.25s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; 
}

.fade-in-1500-down-to-top {
    animation: animationFrames ease 1.5s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards; /*when the spec is finished*/
    -webkit-animation: animationFrames ease 1.5s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: animationFrames ease 1.5s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames ease 1.5s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames ease 1.5s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}

.fade-in-1750-down-to-top {
    animation: animationFrames ease 1.75s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards; /*when the spec is finished*/
    -webkit-animation: animationFrames ease 1.75s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: animationFrames ease 1.75s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames ease 1.75s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames ease 1.75s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}

.fade-in-2000-down-to-top {
    animation: animationFrames ease 2s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards; /*when the spec is finished*/
    -webkit-animation: animationFrames ease 2s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: animationFrames ease 2s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames ease 2s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames ease 2s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}

.fade-in-2250-down-to-top {
    animation: animationFrames ease 2.25s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards; /*when the spec is finished*/
    -webkit-animation: animationFrames ease 2.25s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: animationFrames ease 2.25s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames ease 2.25s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames ease 2.25s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}

.fade-in-2500-down-to-top {
    animation: animationFrames ease 2.5s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards; /*when the spec is finished*/
    -webkit-animation: animationFrames ease 2.5s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: animationFrames ease 2.5s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames ease 2.5s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames ease 2.5s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}

.fade-in-2750-down-to-top {
    animation: animationFrames ease 2.75s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards; /*when the spec is finished*/
    -webkit-animation: animationFrames ease 2.75s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: animationFrames ease 2.75s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames ease 2.75s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames ease 2.75s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}

.fade-in-3000-down-to-top {
    animation: animationFrames ease 3s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:backwards; /*when the spec is finished*/
    -webkit-animation: animationFrames ease 3s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: animationFrames ease 3s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames ease 3s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames ease 3s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}
@keyframes animationFrames{
    0% {
        opacity:0;
        transform:  translate(0px,+100px)  ;
    }
    100% {
        opacity:1;
        transform:  translate(0px,0px)  ;
    }
}

@-moz-keyframes animationFrames{
    0% {
        opacity:0;
        -moz-transform:  translate(0px,+100px)  ;
    }
    100% {
        opacity:1;
        -moz-transform:  translate(0px,0px)  ;
    }
}

@-webkit-keyframes animationFrames {
    0% {
        opacity:0;
        -webkit-transform:  translate(0px,+100px)  ;
    }
    100% {
        opacity:1;
        -webkit-transform:  translate(0px,0px)  ;
    }
}

@-o-keyframes animationFrames {
    0% {
        opacity:0;
        -o-transform:  translate(0px,+100px)  ;
    }
    100% {
        opacity:1;
        -o-transform:  translate(0px,0px)  ;
    }
}

@-ms-keyframes animationFrames {
    0% {
        opacity:0;
        -ms-transform:  translate(0px,+100px)  ;
    }
    100% {
        opacity:1;
        -ms-transform:  translate(0px,0px)  ;
    }
}



@keyframes animationFrames2{
    0% {
        opacity:0;
        transform:  translate(0px,0px)  ;
    }
    100% {
        opacity:1;
        transform:  translate(0px,0px)  ;
    }
}
