.contact-address-section-bg {
    background: linear-gradient(0deg, var(--white) 0%, var(--white) 35%, var(--divelit-grey-05) 20%, var(--divelit-grey-05) 100%);
}

@media screen and (max-width: 576px) {
    .contact-address-company-info {
        border-left: none;
    }
}
@media screen and (min-width: 576px) {
    .contact-address-company-info {
        border-left: none;
    }
}
@media screen and (max-width: 768px) {
    .contact-address-container-padding {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 1rem;
    }
}
@media screen and (min-width: 768px) {
    .contact-address-company-info {
        border-left: solid var(--yellow);
        padding-left: 2em;         
    }
    .contact-address-container-padding {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
    }
}
@media screen and (min-width: 992px) {
    .contact-address-company-info {
        border-left: solid var(--yellow);
        padding-left: 2em;       
    }
    .contact-address-container-padding {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
    }
}
@media screen and (min-width: 1200px) {
    .contact-address-company-info {
        border-left: solid var(--yellow);
        padding-left: 3em;         
    }
    .contact-address-container-padding {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
    }
}
@media screen and (min-width: 1400px) {
    .contact-address-company-info {
        border-left: solid var(--yellow);
        padding-left: 5em;             
    }
    .contact-address-container-padding {
        padding-left: 3rem;
        padding-right: 3rem;
        padding-bottom: 3rem;
    }
}
