@media screen and (max-width: 768px) {
    .our-work-bg-img {
        background-color: var(--divelit-grey-90);
        max-width: 100%;
        height: auto;
    
        max-width: 100%;
        display: block;
        position: relative;
        z-index: 1;
        border-bottom: 1px solid var(--divelit-grey-80);
    }
    .our-work-bg-img::after {
        content: "";
        filter: brightness(30%);
  
        background: url("../../images/pages/home/divelit_d_team.webp");
        background-size: auto;
        background-position: 10rem 0rem;
        background-repeat: no-repeat, no-repeat;
  
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        
    }
}
@media screen and (min-width: 768px) {
    .our-work-bg-img {
        background: url("../../images/pages/home/divelit_d_team.webp");
        background-size: auto;
        background-position: 35rem 3.25rem;
        background-repeat: no-repeat, no-repeat;
    }
}
@media screen and (min-width: 992px) {
    .our-work-bg-img {
        background: url("../../images/pages/home/divelit_d_team.webp");
        background-size: auto;
        background-position: 35rem 4rem;
        background-repeat: no-repeat, no-repeat;
    }
}
@media screen and (min-width: 1200px) {
    .our-work-bg-img {
        background: url("../../images/pages/home/divelit_d_team.webp");
        background-size: auto;
        background-position: right top;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 1400px) {
.our-work-bg-img {
        /**background: url("../images/pages/home/career_vector_v2.svg");*/
        background:  url("../../images/pages/home/divelit_d_team.webp");
        /*background: linear-gradient(0deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 45%, rgba(26, 26, 26, 0.5) 60%, rgba(26, 26, 26, 0.2) 100%), url("../images/pages/software/divelit_code_screen.webp");*/
        background-size: auto;
        background-position: right top;
        background-repeat: no-repeat;
    }
}
  