.blog-older-img-thumbnail-test {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
}
.blog-older-img-thumbnail-test > div.content {
    /*max-width: 100%;*/
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    overflow: hidden;
    object-fit: cover;
}
.blog-older-img-thumbnail-test div.thumbnail {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;

    opacity: 0;
    transition: all 0.3s ease;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    line-height: 100%;

    /*font-size: 30px;*/
    z-index: 3;
}
.blog-older-img-thumbnail-test-dark-75 div {
    background: rgba(0, 0, 0, 0.75);
}
/*.blog-older-img-thumbnail-test:hover > div.content {
    transform: scale(1.1);
}*/
.blog-older-img-thumbnail-test:hover > div.thumbnail {
    opacity: 1;
}


@media screen and (max-width: 360px) {
    .home-services-img-lemon-arrow {
        width: 0;
        height: 0;
        border-left: 4rem solid transparent;
        border-right: 4rem solid transparent;
        border-bottom: 4rem solid var(--divelit-lemon);
        position: absolute;
        left: 50%;
        top: 15rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-lime-arrow {
        width: 0;
        height: 0;
        border-left: 4rem solid transparent;
        border-right: 4rem solid transparent;
        border-bottom: 4rem solid var(--divelit-lime);
        position: absolute;
        left: 50%;
        top: 15rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-grass-arrow {
        width: 0;
        height: 0;
        border-left: 6rem solid transparent;
        border-right: 6rem solid transparent;
        border-bottom: 6rem solid var(--divelit-grass);
        position: absolute;
        left: 50%;
        top: 11rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-plum-70-arrow {
        width: 0;
        height: 0;
        border-left: 4rem solid transparent;
        border-right: 4rem solid transparent;
        border-bottom: 4rem solid var(--divelit-plum-70);
        position: absolute;
        left: 50%;
        top: 15rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-left-size {
        height: 10rem;
        width: 100%;
        object-fit: cover;
    }
}
@media  screen and (min-width: 360px) {
    .home-services-img-lemon-arrow {
        width: 0;
        height: 0;
        border-left: 4rem solid transparent;
        border-right: 4rem solid transparent;
        border-bottom: 4rem solid var(--divelit-lemon);
        position: absolute;
        left: 50%;
        top: 24.5rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-lime-arrow {
        width: 0;
        height: 0;
        border-left: 4rem solid transparent;
        border-right: 4rem solid transparent;
        border-bottom: 4rem solid var(--divelit-lime);
        position: absolute;
        left: 50%;
        top: 24.5rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-grass-arrow {
        width: 0;
        height: 0;
        border-left: 6rem solid transparent;
        border-right: 6rem solid transparent;
        border-bottom: 6rem solid var(--divelit-grass);
        position: absolute;
        left: 50%;
        top: 14rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-plum-70-arrow {
        width: 0;
        height: 0;
        border-left: 4rem solid transparent;
        border-right: 4rem solid transparent;
        border-bottom: 4rem solid var(--divelit-plum-70);
        position: absolute;
        left: 50%;
        top: 24.5rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-left-size {
        height: 13.5rem;
        width: 100%;
        object-fit: cover;
    }
}
@media screen and (min-width: 576px) {
    .home-services-img-lemon-arrow {
        width: 0;
        height: 0;
        border-left: 4rem solid transparent;
        border-right: 4rem solid transparent;
        border-bottom: 4rem solid var(--divelit-lemon);
        position: absolute;
        left: 50%;
        top: 24rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-lime-arrow {
        width: 0;
        height: 0;
        border-left: 4rem solid transparent;
        border-right: 4rem solid transparent;
        border-bottom: 4rem solid var(--divelit-lime);
        position: absolute;
        left: 50%;
        top: 24rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-grass-arrow {
        width: 0;
        height: 0;
        border-left: 6rem solid transparent;
        border-right: 6rem solid transparent;
        border-bottom: 6rem solid var(--divelit-grass);
        position: absolute;
        left: 50%;
        top: 17rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-plum-70-arrow {
        width: 0;
        height: 0;
        border-left: 4rem solid transparent;
        border-right: 4rem solid transparent;
        border-bottom: 4rem solid var(--divelit-plum-70);
        position: absolute;
        left: 50%;
        top: 24rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-left-size {
        height: 17rem;
        width: 100%;
        object-fit: cover;
    }
}
@media screen and (min-width: 768px) {
    .home-services-img-lemon-arrow {
        width: 0;
        height: 0;
        border-left: 4rem solid transparent;
        border-right: 4rem solid transparent;
        border-bottom: 4rem solid var(--divelit-lemon);
        position: absolute;
        left: 50%;
        top: 31rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-lime-arrow {
        width: 0;
        height: 0;
        border-left: 4rem solid transparent;
        border-right: 4rem solid transparent;
        border-bottom: 4rem solid var(--divelit-lime);
        position: absolute;
        left: 50%;
        top: 31rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-grass-arrow {
        width: 0;
        height: 0;
        border-left: 6rem solid transparent;
        border-right: 6rem solid transparent;
        border-bottom: 6rem solid var(--divelit-grass);
        position: absolute;
        left: 50%;
        top: 17rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-plum-70-arrow {
        width: 0;
        height: 0;
        border-left: 4rem solid transparent;
        border-right: 4rem solid transparent;
        border-bottom: 4rem solid var(--divelit-plum-70);
        position: absolute;
        left: 50%;
        top: 31rem;
        transform: translate(-50%, -50%);
    }
    .home-services-img-left-size {
        height: 17rem;
        width: 100%;
        object-fit: cover;
    }
}

@media screen and (min-width: 992px) {
    .home-services-img-lemon-arrow {
        width: 0;
        height: 0;
        border-top: 6rem solid transparent;
        border-bottom: 6rem solid transparent;
        border-left: 4rem solid var(--divelit-lemon);
        position: absolute;
        left: auto;
        margin-left: 2.8rem;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .home-services-img-lime-arrow {
        width: 0;
        height: 0;
        border-top: 6rem solid transparent;
        border-bottom: 6rem solid transparent;
        border-right: 4rem solid var(--divelit-lime);
        position: absolute;
        left: 15.5rem;
        right: auto;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
    .home-services-img-grass-arrow {
        width: 0;
        height: 0;
        border-top: 8rem solid transparent;
        border-bottom: 8rem solid transparent;
        border-left: 6rem solid var(--divelit-grass);
        position: absolute;
        left: auto;
        margin-left: 3rem;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .home-services-img-plum-70-arrow {
        width: 0;
        height: 0;
        border-top: 6rem solid transparent;
        border-bottom: 6rem solid transparent;
        border-left: 4rem solid var(--divelit-plum-70);
        position: absolute;
        left: auto;
        margin-left: 2.8rem;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .home-services-img-left-size {
        height: 32rem;
        object-fit: cover;
    }
}

@media screen and (min-width: 1200px) {
    .home-services-img-lime-arrow {
        width: 0;
        height: 0;
        border-top: 6rem solid transparent;
        border-bottom: 6rem solid transparent;
        border-right: 4rem solid var(--divelit-lime);
        position: absolute;
        left: 18.5rem;
        right: auto;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
}

@media screen and (min-width: 1400px) {
    .home-services-img-lime-arrow {
        width: 0;
        height: 0;
        border-top: 6rem solid transparent;
        border-bottom: 6rem solid transparent;
        border-right: 4rem solid var(--divelit-lime);
        position: absolute;
        left: 22.25rem;
        right: auto;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
}


.home-services-img {
    object-fit: "cover";
}
@media screen and (max-width: 360px) {
    .home-services-img {
        width: 100%;
        height: 15rem;
        object-fit: cover;
        object-position: center 90%;
    }
    .home-services-sw-img-pos {
        object-position: left;
    }
}
@media  screen and (min-width: 360px) {
    .home-services-img {
        width: 100%;
        height: 25rem;
        object-fit: cover;
        object-position: center 87%;
    }
    .home-services-sw-img-pos {
        object-position: left;
    }
}
@media screen and (min-width: 576px) {
    .home-services-img {
        width: 100%;
        height: 25rem;
        object-fit: cover;
        object-position: center 87%;
    }
    .home-services-sw-img-pos {
        object-position: left;
    }
}

@media screen and (min-width: 768px) {
    .home-services-img {
        width: 100%;
        height: 32rem;
        object-fit: cover;
        object-position: center 82%;
    }
    .home-services-sw-img-pos {
        object-position: left;
    }
}
@media screen and (min-width: 992px) {
    .home-services-img {
        width: 100%;
        /*height: 100%;*/
        min-height: 17em;
    }
    .home-services-sw-img-pos {
        object-position: left;
    }
}
@media screen and (min-width: 1200px) {
    .home-services-img {
        width: 100%;
        /*height: 100%;*/

        height: 28rem;
        min-height: 18em;
    }
    .home-services-cybersec-img {
        height: 30rem;
    }
    .home-services-sw-img-pos {
        object-position: left;
    }
}
@media screen and (min-width: 1400px) {
    .home-services-img {
        width: 100%;
        /*height: 100%;*/
        height: 27rem;
        min-height: 18em;
    }
    .home-services-cybersec-img {
        height: 28rem;
    }
    .home-services-sw-img-pos {
        object-position: left;
    }
} 