.embedded-hw-tile-position {
    z-index: 2;       
}
@media screen and (max-width: 360px) {
    .embedded-hw-tile-position {
        margin-top: -3rem;
        margin-right: auto;
        margin-left: auto;
    }
}
@media  screen and (min-width: 360px) {
    .embedded-hw-tile-position {
        margin-top: -3rem;
        margin-right: auto;
        margin-left: auto;
    }
}
@media screen and (min-width: 576px) {
    .embedded-hw-tile-position {
        margin-top: -4rem;
        margin-right: auto;
        margin-left: auto;
    }
}
@media screen and (min-width: 768px) {
    .embedded-hw-tile-position {
        margin-top: -4.5rem;
        margin-right: auto;
        margin-left: auto;
    }
}
@media screen and (min-width: 992px) {
    .embedded-hw-tile-position {
        margin-top: -5rem;
        margin-left: +12rem;       
    }
}
@media screen and (min-width: 1200px) {
    .embedded-hw-tile-position {
        margin-top: -5rem;
        margin-left: +14rem;    
    }
}
@media screen and (min-width: 1400px) {
    .embedded-hw-tile-position {
        margin-top: -5rem;
        margin-left: +17rem;
    }
}
