code {
    max-width: 100%;
    /*display: inline-block;*/
    
}
code > div {
    overflow-x: scroll;
}

.article-categories {
    /*font-family: noto;*/
    padding: 0;
}
.article-categories li {
    display: inline;
}
.article-categories li a {
    color: var(--white);
    text-decoration: none;
}
.article-categories li a:hover {
    color: var(--white);
    text-decoration: underline;
}



.article-slash-padding {
    padding-left: 2em;
    padding-right: 2em;
}




/* TODO - probably DELETE - only used on cunsumer PAGE which will be reworked >>> */
.article-background {
    /*margin-top: 2em;    /* TODO: Remove constant somehow. (was 6em)*/
    /*margin-bottom: 3em;*/
    min-height: 30em;
    color: var(--black);
    background-color: var(--white);
    padding: 0;
}


.article-jumbotron-content {
    /*padding-left: 1em;*/
    padding-top: 20em;
    padding-bottom: 25em;
    height: 70vh;
}
.article-jumbotron-img {
    /*background: linear-gradient(0deg, rgba(0,0,0,0.80) 0%, rgba(0,0,0,0.80) 30%, rgba(0,0,0,0.7) 65%, rgba(0,0,0,0) 100%), url("../../pages//blog/components/office.jpg");*/
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    display: block;
    overflow: hidden;
}
.article-container-profile-img {
    border-radius: 100%;
}
@media screen and (max-width: 576px) {
    .article-jumbotron-content {
        padding-top: 6em;
        padding-bottom: 45em;
        height: 85vh;
    }
    .article-jumbotron-img-filter {
        backdrop-filter: blur(3px);
        text-align: center;
    }
}
@media screen and (min-width: 576px) {
    .article-jumbotron-content {
        padding-top: 10em;
        padding-bottom: 30em;
        height: 70vh;
    }
    .article-jumbotron-img-filter {
        backdrop-filter: blur(3px);
        text-align: center;
    } 
}
@media screen and (min-width: 768px) {
    .article-jumbotron-content {
        padding-top: 15em;
        padding-bottom: 31em;
        height: 70vh;
    }
    .article-jumbotron-img-filter {
        backdrop-filter: blur(3px);
        text-align: center;
    }
}
@media screen and (min-width: 992px) {
    .article-jumbotron-content {
        padding-top: 10em;
        padding-bottom: 27.5em;
        height: 70vh;
    } 
    .article-jumbotron-img-filter {
        backdrop-filter: blur(0px);
        text-align: left;
    }
}
@media screen and (min-width: 1200px) {
    .article-jumbotron-content {
        padding-top: 12.5em;
        padding-bottom: 32.5em;
        height: 70vh;
    }
    .article-jumbotron-img-filter {
        backdrop-filter: blur(0px);
        text-align: left;
    }
}
@media screen and (min-width: 1400px) {
    .article-jumbotron-content {
        padding-top: 20em;
        padding-bottom: 36em;
        height: 70vh;
    }  
    .article-jumbotron-img-filter {
        backdrop-filter: blur(0px);
        text-align: left;
    }
}

/* <<< DELETE */










.article-hero-img {
    width: 100%;
    object-fit: cover;
    border-radius: 0.3rem;
}
@media screen and (max-width: 576px) {
    .article-container-profile-img {
        height: 4em;
        width: 4em;
        object-fit: cover;
    }
    .article-hero-img {
        height: 17rem;
    }
}
@media screen and (min-width: 576px) {
    .article-container-profile-img {
        height: 4em;
        width: 4em;
        object-fit: cover;
    }
    .article-hero-img {
        height: 20rem;
    }
}
@media screen and (min-width: 768px) {
    .article-container-profile-img {
        height: 4.5em;
        width: 4.5em;
        object-fit: cover;
    }
    .article-hero-img {
        height: 30rem;
    }
}
@media screen and (min-width: 992px) {
    .article-container-profile-img {
        height: 5.0em;
        width: 5.0em;
        object-fit: cover;
    }
    .article-hero-img {
        height: 35rem;
    }
}
@media screen and (min-width: 1200px) {
    .article-container-profile-img {
        height: 5.5em;
        width: 5.5em;
        object-fit: cover;
    }
    .article-hero-img {
        height: 45rem;
    }
}
@media screen and (min-width: 1400px) {
    .article-container-profile-img {
        height: 6em;
        width: 6em;
        object-fit: cover;
    }
    .article-hero-img {
        height: 50rem;
    }
}
.article-hero-section {
    background: var(--divelit-grey-90);
}





.article-hero-margin {
    margin-left: 20rem;
    margin-right: 20rem;
}
