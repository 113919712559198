

.career-work-position-button {
    animation-name: test-back;
    animation-duration: 0.3s;
}

.career-work-position-button:hover {
    animation-name: test-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

@keyframes test-forth { 
    from {
        background-color: var(--white);
    }
    to { 
        background-color: var(--divelit-grey-05);
    }
}
@keyframes test-back  { 
    from { 
        background-color: var(--divelit-grey-05);
    }
    to {
        background-color: var(--white);
    }
}

.collapsed {
    background-color: var(--white);
    
    animation-name: career-work-position-btn-back;
    animation-duration: 0.3s;
}
.collapsed:hover {
    animation-name: career-work-position-btn-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

@keyframes career-work-position-btn-forth { 
    from {
        background-color: var(--white);
        opacity: 0.5;
    }
    to { 
        background-color: var(--divelit-grey-05);
        opacity: 1.0;
    }
}
@keyframes career-work-position-btn-back  { 
    from { 
        background-color: var(--divelit-grey-05);
        opacity: 1.0;
    }
    to {
        background-color: var(--white);
        opacity: 0.5;
    }
}


.collapsed {
    /*background-color: var(--divelit-grey-03);*/
    /*color: var(--divelit-grey-50);*/
    opacity: 0.5;
}

@media screen and (max-width: 576px) {
    .work-position-icon {
        height: 2.5em;  
    }  
}
@media screen and (min-width: 576px) {
    .work-position-icon {
        height: 2.5em;  
    }  
}
@media screen and (min-width: 768px) {
    .work-position-icon {
        height: 3em;
    }    
}
@media screen and (min-width: 992px) {
    #positions {
        position: relative;
        top: -4rem;
    }
    .work-position-icon {
        height: 3.25em;
    }
}
@media screen and (min-width: 1200px) {
    #positions {
        position: relative;
        top: -5rem;
    }
    .work-position-icon {
        height: 3.5em;
    }
}
@media screen and (min-width: 1400px) {
    #positions {
        position: relative;
        top: -5rem;
    }
    .work-position-icon {
        height: 4em;
    }
}
