.project-tile-border {
    border-radius: 0.3rem;
}

.project-tile-box-shadow-divelit-grey-10 {
    /*box-shadow: 1px 1px 20px 4px var(--divelit-grey-10);*/
    box-shadow: 1px 1px 20px 4px rgba(0,0,0,0.1);
}

.project-tile-box-shadow-divelit-grey-20 {
    box-shadow: 1px 1px 20px 4px var(--divelit-grey-20);
}

.w-fit-content {
    width: fit-content;
}


.project-tile {
    position: relative;
}

@media screen and (max-width: 360px) {
    .project-tile {

    }
    .project-2n-info-tile-img {
        height: 60vw;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-numazon-tile-img {
        height: 60vw;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-linet-sprint-tile-img {
        height: 60vw;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-linet-service-app-tile-img {
        height: 50vw;
        margin-top: -4rem;
        margin-bottom: 1rem;
    }
    .project-divelit-is-tile-img {
        height: 35vw;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-veit-bat2ng-img {
        height: 18rem;
        margin-top: -6rem;
    }
}
@media  screen and (min-width: 360px) {
    .project-tile {

    }
    .project-2n-info-tile-img {
        height: 70vw;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-numazon-tile-img {
        height: 75vw;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-linet-sprint-tile-img {
        height: 65vw;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-linet-service-app-tile-img {
        height: 57vw;
        margin-top: -5rem;
        margin-bottom: 0rem;
    }
    .project-divelit-is-tile-img {
        height: 40vw;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-veit-bat2ng-img {
        height: 22rem;
        margin-top: -6rem;
    }
}
@media screen and (min-width: 576px) {
    .project-tile {

    }
    .project-2n-info-tile-img {
        height: 25rem;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-numazon-tile-img {
        height: 30rem;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-linet-sprint-tile-img {
        height: 25rem;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-linet-service-app-tile-img {
        height: 20rem;
        margin-top: -5rem;
        margin-bottom: 0rem;
    }
    .project-divelit-is-tile-img {
        height: 15rem;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-veit-bat2ng-img {
        height: 35rem;
        margin-top: -6rem;
        text-align: center;
    }
}
@media screen and (min-width: 768px) {
    .project-tile {

    }
    .project-2n-info-tile-img {
        height: 30rem;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-numazon-tile-img {
        height: 35rem;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-linet-sprint-tile-img {
        height: 30rem;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-linet-service-app-tile-img {
        height: 30rem;
        margin-top: -5rem;
        margin-bottom: 0rem;
    }
    .project-divelit-is-tile-img {
        height: 20rem;
        margin-top: -5rem;
        margin-bottom: 1rem;
    }
    .project-veit-bat2ng-img {
        height: 40rem;
        margin-top: -6rem;
        text-align: center;
    }

}
@media screen and (min-width: 992px) {
    .project-tile {
        height: auto;
    }
    .project-2n-info-tile-img {
        height: 21rem;
        margin: 0;
        position: absolute;
        top: 4rem;
        right: -2rem;
    }
    .project-numazon-tile-img {
        height: 26rem;
        margin: 0;
        position: absolute;
        top: +5rem;
        right: -1.5rem;
    }
    .project-linet-sprint-tile-img {
        height: 22rem;
        margin: 0;
        position: absolute;
        top: 6rem;
        left: -1rem;
    }
    .project-linet-service-app-tile-img {
        height: 17rem;
        margin: 0;
        position: absolute;
        top: 11rem;
        left: -1.5rem;
    }
    .project-divelit-is-tile-img {
        height: 14rem;
        margin: 0;
        position: absolute;
        top: 8.5rem;
        right: -1rem;
    }
    .project-veit-bat2ng-img {
        height: 38rem;
        margin: 0;
        position: absolute;
        top: -2rem;
        left: -4rem;
    }
}
@media screen and (min-width: 1200px) {
    .project-tile {
        height: auto;
    }
    .project-2n-info-tile-img {
        height: 26rem;
        margin: 0;
        position: absolute;
        top: 2rem;
        right: -2.5rem;
    }
    .project-numazon-tile-img {
        height: 34rem;
        margin: 0;
        position: absolute;
        top: +5rem;
        right: -2.5rem;
    }
    .project-linet-sprint-tile-img {
        height: 27rem;
        margin: 0;
        position: absolute;
        top: 6rem;
        left: -2rem;
    }
    .project-linet-service-app-tile-img {
        height: 21rem;
        margin: 0;
        position: absolute;
        top: 10rem;
        left: -2.5rem;
    }    
    .project-divelit-is-tile-img {
        height: 19rem;
        margin: 0;
        position: absolute;
        top: 7rem;
        right: -2rem;
    }
    .project-veit-bat2ng-img {
        height: 48rem;
        margin: 0;
        position: absolute;
        top: -6rem;
        left: -4rem;
    }
}
@media screen and (min-width: 1400px) {
    .project-tile {
        height: auto;
    }
    .project-2n-info-tile-img {
        height: 35rem;
        margin: 0;
        position: absolute;
        top: -2rem;
        right: -3rem;
    }
    .project-numazon-tile-img {
        height: 43rem;
        margin: 0;
        position: absolute;
        top: -3rem;
        right: -3rem;
    }
    .project-linet-sprint-tile-img {
        height: 32rem;
        margin: 0;
        position: absolute;
        top: 3rem;
        left: -3rem;
    }
    .project-linet-service-app-tile-img {
        /*height: calc(min(35rem, 32vw));*/
        height: 24rem;
        margin: 0;
        position: absolute;
        top: 8rem;
        left: -3rem;
    }
    .project-divelit-is-tile-img {
        height: 26rem;
        margin: 0;
        position: absolute;
        top: 6rem;
        right: -3rem;
    }
    .project-veit-bat2ng-img {
        height: 48rem;
        margin: 0;
        position: absolute;
        top: -6rem;
        left: -2rem;
    }
}



@media screen and (max-width: 576px) {
    .project-square-img {
        height: 2.5em;
    }
}
@media screen and (min-width: 576px) {
    .project-square-img {
        height: 2.5rem;
    }
}
@media screen and (min-width: 768px) {
    .project-square-img {
        height: 3rem;
    }
}
@media screen and (min-width: 992px) {
    .project-square-img {
        height: 3rem;
    }
}
@media screen and (min-width: 1200px) {
    .project-square-img {
       height: 3rem;
    }
}
@media screen and (min-width: 1400px) {
    .project-square-img {
       height: 3rem;
    }
}
