.footer {
    background-color: var(--divelit-grey-90);
}



.footer-logo {
    height: 2rem;
}



.footer-link {
    text-decoration: none;
    animation-name: link-back;
    animation-duration: 0.3s;
}
.footer-link:hover {
    animation-name: link-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes link-forth { 
    from { 
    } 
    to { 
        color: var(--white);
    } 
} 
@keyframes link-back { 
    from { 
        color: var(--white);
    } 
    to { 
    } 
} 

.footer-consortium-avdzp {
    height: 4.4rem;
    filter: brightness(0.8) invert(0.3);
    animation-name: avdzp-back;
    animation-duration: 0.3s;
}
.footer-consortium-avdzp:hover {
    animation-name: avdzp-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes avdzp-forth { 
    from { 
        filter: brightness(0.8) invert(0.3);
    } 
    to { 
        filter: brightness(0) invert(1);
    } 
} 
@keyframes avdzp-back { 
    from { 
        filter: brightness(0) invert(1);
    } 
    to { 
        filter: brightness(0.8) invert(0.3);
    } 
} 

.footer-consortium-rhkbrno {
    height: 4rem;
    animation-name: rhkbrno-back;
    animation-duration: 0.3s;
    filter: brightness(0.5) invert(0.2);
}
.footer-consortium-rhkbrno:hover {
    animation-name: rhkbrno-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes rhkbrno-forth { 
    from { 
        filter: brightness(0.5) invert(0.2);
    } 
    to { 
        filter: brightness(0) invert(1);
    } 
} 
  
@keyframes rhkbrno-back { 
    from { 
        filter: brightness(0) invert(1);
    } 
    to { 
        filter: brightness(0.5) invert(0.2);
    } 
} 

.footer-consortium-ohkol {
    height: 6rem;
    animation-name: ohkol-back;
    animation-duration: 0.3s;
    filter: brightness(0.6) invert(0.2);
}
.footer-consortium-ohkol:hover {
    animation-name: ohkol-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes ohkol-forth { 
    from { 
        filter: brightness(0.6) invert(0.2);
    } 
    to { 
        filter: brightness(0) invert(1);
    } 
} 
@keyframes ohkol-back { 
    from { 
        filter: brightness(0) invert(1);
    } 
    to { 
        filter: brightness(0.6) invert(0.2);
    } 
} 

.footer-consortium-eia {
    height: 3.5rem;
    animation-name: eia-back;
    animation-duration: 0.3s;
    filter: brightness(0.5);
}
.footer-consortium-eia:hover {
    animation-name: eia-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes eia-forth { 
    from { 
        filter: brightness(0.5);
    } 
    to { 
        filter: brightness(1) invert(0);
    } 
} 
@keyframes eia-back { 
    from { 
        filter: brightness(1) invert(0);
    } 
    to { 
        filter: brightness(0.5);
    } 
} 

.footer-consortium-amsp {
    height: 4rem;
    animation-name: amsp-back;
    animation-duration: 0.3s;
    filter: brightness(0.9) invert(0.4);
}
.footer-consortium-amsp:hover {
    animation-name: amsp-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes amsp-forth { 
    from { 
        filter: brightness(0.9) invert(0.4);
    } 
    to { 
        filter: brightness(0) invert(1);
    } 
} 
@keyframes amsp-back { 
    from { 
        filter: brightness(0) invert(1);
    } 
    to { 
        filter: brightness(0.9) invert(0.4);
    } 
} 

.footer-consortium-hl7 {
    height: 4rem;
    animation-name: hl7-back;
    animation-duration: 0.3s;
    filter: brightness(0) invert(0.4);
}
.footer-consortium-hl7:hover {
    animation-name: hl7-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes hl7-forth { 
    from { 
        filter: brightness(0) invert(0.4);
    } 
    to { 
        filter: brightness(0) invert(1);
    } 
} 
@keyframes hl7-back { 
    from { 
        filter: brightness(0) invert(1);
    } 
    to { 
        filter: brightness(0) invert(0.4);
    } 
} 


.footer-link-mail {
    text-decoration: underline;
    animation-name: mail-back;
    animation-duration: 0.3s;
}
.footer-link-mail:hover {
    animation-name: mail-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes mail-forth { 
    from { 
    } 
    to { 
        color: var(--white);
    } 
} 
@keyframes mail-back { 
    from { 
        color: var(--white);
    } 
    to { 
    } 
} 


.footer-link-icon {
    text-decoration: none;
    font-size: 2.5rem;
    animation-name: hl7-back;
    animation-duration: 0.3s;
    color: var(--divelit-grey-50);
}
.footer-link-icon:hover {
    animation-name: icon-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes icon-forth { 
    from { 
        color: var(--divelit-grey-50);
    } 
    to { 
        color: var(--white);
    } 
} 
@keyframes icon-back { 
    from { 
        color: var(--white);
    } 
    to {
        color: var(--divelit-grey-50);
    } 
} 



.footer-navbar {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
@media screen and (max-width: 768px) {
    .footer-navbar {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}
@media screen and (min-width: 768px) {
    .footer-navbar {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}



.footer-copyright {
    text-decoration: underline;
    animation-name: copyright-back;
    animation-duration: 0.3s;
}
.footer-copyright:hover {
    animation-name: copyright-forth;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    cursor: pointer;
}
@keyframes copyright-forth { 
    from { 
    } 
    to { 
        color: var(--white);
    } 
} 
@keyframes copyright-back { 
    from { 
        color: var(--white);
    } 
    to { 
    } 
} 
