/* THIS ANIAMTION IS BASED UPON BUTTON:HOVER ANIMATION - so for guigelines for adjustments look into btn-hover.css file */ 

/* this class serves as 1. bottom layer that cover whole screen with white background */
.transition-animation {
    border: none;
    height: 100vh;
    width: 100vw;
    position: relative;
    background-color: var(--white);
    transition: .5s ease;
    /* z-index of the NAVBAR is 1300 so to hide navbar animation must have higher number*/
    z-index: 1301;
    /* hide overflow - othervise both scrollbars will be shown */
    overflow: hidden;
}

/* this 2. layer sits above transition-animation layer and displays divelit logo (can also be used for text display) */
p.transition-animation-logo {
    /* whole element properties */
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1;
    /* background image properties*/
    /*background-image: url("../logos/divelit.svg"), url("../animations/Pulse-1s-200px-black.svg");
    background-size: 30%, 3%;
    background-repeat: no-repeat;
    background-position: center, center;*/
    background-image: url("../../animations/divelit-pulsing-stripes.svg");
    background-size: 40% 50%;
    background-repeat: no-repeat;
    background-position: center;
}

/* This is the 3. layer which consists of yellow trapezoid that slides in and yout of the screen between layers 2) & 1) */
.transition-animation-inner {
    height: 100%;
    width: 400%;
    position: relative;
    left: -400%;
    /*top: -100%;*/
    border: none;
    background-color: var(--yellow);
    text-align: center;
    /* this layer is bellow layer 2) but above layer 1) */
    z-index: 0;
    
    /* TODO - check whether it is truly neded to use browser specific attributes or not
     - it seems that it is no longer neeeded and that browser now supports universal attributes*/

    /*-webkit-transform: skew(50deg) translate(400%,0);
    -moz-transform: skew(50deg) translate(400%,0);
    -o-transform: skew(50deg) translate(400%,0);*/
    transform: skew(50deg) translate(400%,0);
    /*-webkit-animation-name: transition-out;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: 2s cubic-bezier(.48,.51,.85,-0.03);
    -moz-animation-name: transition-out;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: 2s cubic-bezier(.48,.51,.85,-0.03);*/
    animation-name: transition-out;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-timing-function: 2s ease-in-out;
}

@keyframes transition-in {
    from {transform:skew(50deg) translate(-400%, 0);}
    to {transform:skew(50deg) translate(-20px, -0px);}    
}
@keyframes transition-out {
    from {transform: skew(50deg) translate(0, 0);}
    to {transform: skew(50deg) translate(400%, -0px);}
}
/*
@-webkit-keyframes transition-in {
    from {transform:skew(50deg) translate(-400%, 0);}
    to {transform:skew(50deg) translate(-20px, -0px);}    
}
@-webkit-keyframes transition-out {
    from {transform: skew(50deg) translate(0, 0);}
    to {transform: skew(50deg) translate(400%, -0px);}
}*/
